/* Mixins */
.legacy {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: auto;
  width: calc(100% - 30px);
  max-width: 1224px;
  /* To remove fluidity in viewports, uncomment this block.
    @include respond(medium-up) {
        width: $medium-breakpoint - $gutters*2;
    }
    @include respond(large-up) {
        width: $large-breakpoint - $gutters*2;
    }
    @include respond(desktop) {
        width: $desktop-breakpoint - $gutters*2;
    }
    */
  min-height: 50vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: white;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-bottom: 40px;
  margin-bottom: 50px; }
  @media only screen and (min-width: 768px) {
    .legacy {
      display: block; } }
  .legacy .highlight {
    background-color: transparent; }
  .legacy .shadow {
    -webkit-box-shadow: none !important;
            box-shadow: none !important; }
  .legacy ul,
  .legacy ol {
    margin: 0;
    padding: 0; }
    .legacy ul li,
    .legacy ol li {
      list-style: none; }

.responsive-grid {
  display: block;
  list-style: none;
  width: 100%;
  max-width: 1025px;
  margin: auto;
  padding: 0; }
  .responsive-grid::after {
    content: "";
    display: table;
    clear: both; }
  .responsive-grid *,
  .responsive-grid *::before,
  .responsive-grid *::after {
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
  .responsive-grid .grid-col {
    display: block;
    width: 100%;
    float: left; }

h1,
.h1,
.heading1 {
  font-weight: 700;
  font-size: 32px;
  font-size: 2rem;
  line-height: 1em; }
  @media only screen and (min-width: 768px) {
    h1,
    .h1,
    .heading1 {
      font-size: 48px;
      font-size: 3rem; } }

h2,
.h2,
.heading2 {
  font-weight: 700;
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1em; }
  @media only screen and (min-width: 768px) {
    h2,
    .h2,
    .heading2 {
      font-weight: 700;
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 1em; } }

h3,
.h3,
.heading3 {
  font-weight: 700;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1em; }
  @media only screen and (min-width: 768px) {
    h3,
    .h3,
    .heading3 {
      font-size: 32px;
      font-size: 2rem;
      line-height: 1em; } }

h4,
.h4,
.heading4 {
  font-weight: 700;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1em; }
  @media only screen and (min-width: 768px) {
    h4,
    .h4,
    .heading4 {
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 1em; } }

h5,
.h5,
.heading5 {
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1em; }
  @media only screen and (min-width: 768px) {
    h5,
    .h5,
    .heading5 {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 1em; } }

.subheading1 {
  font-weight: 700;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1em; }
  @media only screen and (min-width: 768px) {
    .subheading1 {
      font-size: 32px;
      font-size: 2rem;
      line-height: 1em; } }

.subheading2 {
  font-weight: 700;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1em; }
  @media only screen and (min-width: 768px) {
    .subheading2 {
      font-size: 32px;
      font-size: 2rem;
      line-height: 1em; } }

.subheading3 {
  font-weight: 700;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1em; }
  @media only screen and (min-width: 768px) {
    .subheading3 {
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 1em; } }

p,
.p,
.p1,
.paragraph1 {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.28571em; }
  @media only screen and (min-width: 768px) {
    p,
    .p,
    .p1,
    .paragraph1 {
      font-size: 16px;
      font-size: 1rem;
      line-height: 1.5em; } }

p.small,
.p2,
.paragraph2 {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.28571em; }
  @media only screen and (min-width: 768px) {
    p.small,
    .p2,
    .paragraph2 {
      font-size: 16px;
      font-size: 1rem;
      line-height: 1.5em; } }

.pdp-name {
  font-family: SofiaPro, Arial, sans-serif;
  font-weight: 400;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333em; }
  @media only screen and (min-width: 768px) {
    .pdp-name {
      font-family: SofiaPro, Arial, sans-serif;
      font-weight: 400;
      font-size: 32px;
      font-size: 2rem;
      line-height: 1.25em; } }

.pdp-item-number {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.14286em; }
  @media only screen and (min-width: 768px) {
    .pdp-item-number {
      font-size: 16px;
      font-size: 1rem;
      line-height: 1em; } }

.pdp-price,
.price-standard,
.price-sales {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1em; }
  @media only screen and (min-width: 768px) {
    .pdp-price,
    .price-standard,
    .price-sales {
      font-size: 32px;
      font-size: 2rem; } }

.pdp-sale-price,
.price-standard ~ .price-sales {
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1em;
  color: #e62144; }
  @media only screen and (min-width: 768px) {
    .pdp-sale-price,
    .price-standard ~ .price-sales {
      font-size: 24px;
      font-size: 1.5rem; } }

.cart-bundle-pricing .price-standard,
.cart-bundle-pricing .price-sales {
  font-size: 14px;
  font-size: 0.875rem; }

.recommender-carousel .standardprice,
.recommender-carousel .price-standard,
.recommender-carousel .price-sales,
.recommender-carousel .price-standard ~ .price-sales {
  font-size: 1.1875rem;
  font-weight: 700; }

.tile-name,
.product-tile .product-name {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1em; }

.tile-price,
.product-standard-price,
.product-sales-price {
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1em; }
  @media only screen and (min-width: 768px) {
    .tile-price,
    .product-standard-price,
    .product-sales-price {
      font-size: 19px;
      font-size: 1.1875rem; } }

.tile-sale-price,
.product-standard-price ~ .product-sales-price {
  color: #e62144;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1em; }
  @media only screen and (min-width: 768px) {
    .tile-sale-price,
    .product-standard-price ~ .product-sales-price {
      font-size: 19px;
      font-size: 1.1875rem; } }

.sidebar-heading {
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.11111em; }
  @media only screen and (min-width: 768px) {
    .sidebar-heading {
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 1.08333em; } }

.refine-attr-title {
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 0.77778em; }
  @media only screen and (min-width: 768px) {
    .refine-attr-title {
      line-height: 1em; } }

.pagination-items {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.14286em; }
  @media only screen and (min-width: 768px) {
    .pagination-items {
      line-height: 1em; } }

.minicart-quantity {
  font-weight: 700;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1em;
  color: #0056a2; }
  @media only screen and (min-width: 1025px) {
    .minicart-quantity {
      font-size: 16px;
      font-size: 1rem; } }

.breadcrumb-element,
.breadcrumb-refined-by,
.breadcrumb-refinement,
.breadcrumb-result-text {
  font-size: 12px;
  font-size: 0.75rem; }
  @media only screen and (min-width: 768px) {
    .breadcrumb-element,
    .breadcrumb-refined-by,
    .breadcrumb-refinement,
    .breadcrumb-result-text {
      font-size: 14px;
      font-size: 0.875rem; } }

.bulleted-list,
ul.list {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.28571em;
  margin: 0;
  padding: 0 0 0 1em; }
  @media only screen and (min-width: 768px) {
    .bulleted-list,
    ul.list {
      font-size: 16px;
      font-size: 1rem;
      line-height: 1.5em; } }
  .bulleted-list .list-item,
  .bulleted-list li,
  ul.list .list-item,
  ul.list li {
    list-style-type: disc;
    display: list-item;
    list-style-position: outside; }

.numbered-list,
ol.list {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.28571em;
  margin: 0;
  padding: 0 0 0 1em; }
  @media only screen and (min-width: 768px) {
    .numbered-list,
    ol.list {
      font-size: 16px;
      font-size: 1rem;
      line-height: 1.5em; } }
  .numbered-list .list-item,
  .numbered-list li,
  ol.list .list-item,
  ol.list li {
    list-style-type: decimal;
    display: list-item;
    list-style-position: outside; }

a,
.link {
  color: #0056a2;
  text-decoration: none; }
  a:hover, a:focus,
  .link:hover,
  .link:focus {
    color: #0056a2;
    text-decoration: underline; }

.alt-link {
  color: #0056a2;
  text-decoration: none; }
  .alt-link:hover, .alt-link:focus {
    color: #e62144;
    text-decoration: none; }

.utility-link {
  color: #0056a2;
  text-decoration: none;
  font-size: 14px;
  font-size: 0.875rem; }
  .utility-link:hover, .utility-link:focus {
    color: #e62144;
    text-decoration: none; }

.nav-topcat-link {
  color: #1d3557;
  text-decoration: none;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1em;
  font-weight: 700; }
  .nav-topcat-link:hover, .nav-topcat-link:focus {
    color: #1d3557;
    text-decoration: none; }
  @media only screen and (min-width: 1025px) {
    .nav-topcat-link:hover, .nav-topcat-link:focus {
      color: #840621; } }

a.breadcrumb-element,
a.breadcrumb-refinement {
  color: #0056a2;
  text-decoration: none; }
  a.breadcrumb-element:hover, a.breadcrumb-element:focus,
  a.breadcrumb-refinement:hover,
  a.breadcrumb-refinement:focus {
    color: #e62144; }

.cta-link {
  color: #0056a2;
  text-decoration: none;
  font-size: 14px;
  font-size: 0.875rem; }
  .cta-link:hover, .cta-link:focus {
    color: #e62144;
    text-decoration: none; }

.refinement-link {
  color: #0056a2;
  text-decoration: none;
  font-size: 14px;
  font-size: 0.875rem; }
  .refinement-link:hover, .refinement-link:focus {
    color: #e62144;
    text-decoration: none; }

.refinement-subcat-link {
  color: #0056a2;
  text-decoration: none;
  font-size: 14px;
  font-size: 0.875rem; }
  .refinement-subcat-link:hover, .refinement-subcat-link:focus {
    color: #e62144;
    text-decoration: none; }

.refinement-tertiary-link {
  color: #0056a2;
  text-decoration: none;
  font-size: 14px;
  font-size: 0.875rem; }
  .refinement-tertiary-link:hover, .refinement-tertiary-link:focus {
    color: #e62144;
    text-decoration: none; }

.selected {
  color: #e62144; }
  .selected:hover, .selected:focus {
    color: #e62144; }

.footer-link {
  color: #646464;
  text-decoration: none;
  font-size: 14px;
  font-size: 0.875rem; }
  .footer-link:hover, .footer-link:focus {
    color: #646464;
    text-decoration: underline; }

.pdp-attr-label {
  font-family: SofiaPro, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.42857em; }
  @media only screen and (min-width: 768px) {
    .pdp-attr-label {
      font-family: SofiaPro, Arial, sans-serif;
      font-weight: 400;
      font-size: 16px;
      font-size: 1rem;
      line-height: 1.5em; } }

.pdp-attr {
  font-family: SofiaPro, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.33333em;
  color: #616161;
  padding: 6px 0 12px; }
  @media only screen and (min-width: 768px) {
    .pdp-attr {
      font-family: SofiaPro, Arial, sans-serif;
      font-weight: 400;
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 1.42857em; } }

.pdp-promo-link {
  font-weight: 700;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1em;
  text-transform: uppercase; }
  @media only screen and (min-width: 768px) {
    .pdp-promo-link {
      font-size: 14px;
      font-size: 0.875rem; } }

.tab-text {
  text-transform: uppercase;
  color: rgba(29, 53, 87, 0.5);
  line-height: 1.5em;
  font-weight: 700;
  font-size: 12px;
  font-size: 0.75rem; }
  @media only screen and (min-width: 768px) {
    .tab-text {
      font-size: 14px;
      font-size: 0.875rem; } }

.promo-callout {
  color: #e62144;
  font-weight: 700;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1em;
  text-transform: uppercase; }

.grid-tile-attr-label {
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.42857em; }

.grid-items {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1em; }

.article-title-1 {
  font-weight: 400;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1em; }
  @media only screen and (min-width: 768px) {
    .article-title-1 {
      font-size: 30px;
      font-size: 1.875rem; } }

.article-title-2 {
  font-weight: 400;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1em; }
  @media only screen and (min-width: 768px) {
    .article-title-2 {
      font-size: 20px;
      font-size: 1.25rem; } }

.legal-info {
  font-size: 11px;
  font-size: 0.6875rem;
  line-height: 1.63636em; }
  @media only screen and (min-width: 768px) {
    .legal-info {
      font-size: 12px;
      font-size: 0.75rem; } }

.promo-title {
  font-weight: 700;
  font-size: 11px;
  font-size: 0.6875rem;
  line-height: 1.45455em;
  text-transform: uppercase; }
  @media only screen and (min-width: 768px) {
    .promo-title {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 1.33333em; } }

.promo-subtitle {
  font-weight: 400;
  font-size: 11px;
  font-size: 0.6875rem;
  line-height: 1.45455em; }
  @media only screen and (min-width: 768px) {
    .promo-subtitle {
      font-size: 16px;
      font-size: 1rem;
      line-height: 1.25em; } }

.tile-promo-msg {
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
  color: #e62144;
  line-height: 1.5; }

.small-link {
  color: #0056a2;
  text-decoration: none;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.5em; }
  .small-link:hover, .small-link:focus {
    color: #e62144;
    text-decoration: none; }

.icon {
  display: inline-block;
  vertical-align: middle;
  fill: #0056a2; }
  .icon.flipped {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg); }
  .icon.rotate-right {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .icon.rotate-left {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  .icon.hoverable:hover {
    fill: #0056a2; }
  .icon.header-icons {
    fill: #0056a2; }
    .icon.header-icons:hover {
      fill: #0056a2; }
  .icon.social-icons {
    fill: #0056a2; }
    .icon.social-icons:hover {
      fill: #0056a2; }
  .icon.gift-box, .icon.store-locator, .icon.account-icon {
    fill: #0056a2; }
  .icon.checkmark {
    fill: #40a949; }
  .icon.radio-dot {
    fill: #40a949; }
  .icon.down-arrow {
    fill: #0056a2; }
  .icon.right-arrow.small, .icon.right-arrow-white.small {
    height: 1.1875rem;
    width: 0.6875rem; }
  .icon.filter-icon {
    fill: #3fb1d4; }
  .icon.close {
    -webkit-transition: -webkit-transform 0.1s;
    transition: -webkit-transform 0.1s;
    transition: transform 0.1s;
    transition: transform 0.1s, -webkit-transform 0.1s; }
    *:hover > .icon.close {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
  .icon.svg-remove-dims {
    width: 24px;
    height: 24px; }

.legacy fieldset {
  border: 0 none;
  margin: 0;
  padding: 0; }

.legacy table {
  width: 100%; }

.legacy th {
  text-align: left; }

.legacy td {
  padding: 1em;
  vertical-align: top; }

.legacy h1,
.legacy h2,
.legacy h3 {
  margin: 0.25em 0; }

.legacy ul,
.legacy ol {
  margin: 0;
  padding: 0; }

.legacy li {
  list-style: none; }

.legacy address {
  font-style: normal; }

.legacy picture,
.legacy picture source {
  display: block;
  font-size: 0; }

.legacy .bold {
  font-weight: 700; }

.legacy .nonbold {
  font-weight: 400; }

.legacy .italic {
  font-style: italic; }

.legacy .nonitalic {
  font-style: normal; }

.legacy .uppercase {
  text-transform: uppercase; }

.legacy .use-hand {
  cursor: pointer; }

.legacy .underline {
  text-decoration: underline; }

.legacy .nounderline {
  text-decoration: none; }

.legacy .video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden; }

.legacy .video-container iframe,
.legacy .video-container object,
.legacy .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.legacy .disclaimer-trigger {
  cursor: pointer; }

.legacy .disclaimer-text {
  display: none; }

/* button()
 * @description Insert button styling. Default (no param) will return primary button styles. If a param is passed, certain style rules are ommitted initially, as they would just be immediately overridden.
 * @param $class - String. Optional. The desired button style, ex: secondary. Acceptable values: 'primary' (default), 'primary-alt', 'secondary', 'secondary-alt', 'tertiary'
 * @example: a.my-link {@include button('secondary')}
 */
.legacy button,
.legacy .button,
.legacy .buttonstyle,
.legacy input[type="button"] {
  cursor: pointer;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: auto;
  padding: 8px;
  gap: 8px;
  font-family: SofiaPro, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: normal;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  min-width: 80px;
  color: #fff;
  background-color: #e62144;
  border-color: #e62144;
  max-width: 286px; }
  .legacy button:hover, .legacy button:focus, .legacy button:active, .legacy button.active,
  .legacy .button:hover,
  .legacy .button:focus,
  .legacy .button:active,
  .legacy .button.active,
  .legacy .buttonstyle:hover,
  .legacy .buttonstyle:focus,
  .legacy .buttonstyle:active,
  .legacy .buttonstyle.active,
  .legacy input[type="button"]:hover,
  .legacy input[type="button"]:focus,
  .legacy input[type="button"]:active,
  .legacy input[type="button"].active {
    text-decoration: none; }
  .legacy button:focus,
  .legacy .button:focus,
  .legacy .buttonstyle:focus,
  .legacy input[type="button"]:focus {
    outline: -webkit-focus-ring-color auto 5px; }
  .legacy button:active,
  .legacy .button:active,
  .legacy .buttonstyle:active,
  .legacy input[type="button"]:active {
    cursor: default;
    background-color: #a5001d;
    border-color: #a5001d; }
  .legacy button.disabled, .legacy button[disabled],
  fieldset[disabled] .legacy button,
  .legacy .button.disabled,
  .legacy .button[disabled],
  fieldset[disabled]
  .legacy .button,
  .legacy .buttonstyle.disabled,
  .legacy .buttonstyle[disabled],
  fieldset[disabled]
  .legacy .buttonstyle,
  .legacy input[type="button"].disabled,
  .legacy input[type="button"][disabled],
  fieldset[disabled]
  .legacy input[type="button"] {
    cursor: default;
    background-color: #b7b7b7;
    border-color: #b7b7b7; }
  .legacy button:hover, .legacy button:focus, .legacy button:active, .legacy button.active,
  .legacy .button:hover,
  .legacy .button:focus,
  .legacy .button:active,
  .legacy .button.active,
  .legacy .buttonstyle:hover,
  .legacy .buttonstyle:focus,
  .legacy .buttonstyle:active,
  .legacy .buttonstyle.active,
  .legacy input[type="button"]:hover,
  .legacy input[type="button"]:focus,
  .legacy input[type="button"]:active,
  .legacy input[type="button"].active {
    color: #fff;
    background-color: #d00a2d;
    border-color: #d00a2d; }
  .legacy button.disabled:hover, .legacy button.disabled:focus, .legacy button.disabled:active, .legacy button.disabled.active, .legacy button[disabled]:hover, .legacy button[disabled]:focus, .legacy button[disabled]:active, .legacy button[disabled].active,
  fieldset[disabled] .legacy button:hover,
  fieldset[disabled] .legacy button:focus,
  fieldset[disabled] .legacy button:active,
  fieldset[disabled] .legacy button.active,
  .legacy .button.disabled:hover,
  .legacy .button.disabled:focus,
  .legacy .button.disabled:active,
  .legacy .button.disabled.active,
  .legacy .button[disabled]:hover,
  .legacy .button[disabled]:focus,
  .legacy .button[disabled]:active,
  .legacy .button[disabled].active,
  fieldset[disabled]
  .legacy .button:hover,
  fieldset[disabled]
  .legacy .button:focus,
  fieldset[disabled]
  .legacy .button:active,
  fieldset[disabled]
  .legacy .button.active,
  .legacy .buttonstyle.disabled:hover,
  .legacy .buttonstyle.disabled:focus,
  .legacy .buttonstyle.disabled:active,
  .legacy .buttonstyle.disabled.active,
  .legacy .buttonstyle[disabled]:hover,
  .legacy .buttonstyle[disabled]:focus,
  .legacy .buttonstyle[disabled]:active,
  .legacy .buttonstyle[disabled].active,
  fieldset[disabled]
  .legacy .buttonstyle:hover,
  fieldset[disabled]
  .legacy .buttonstyle:focus,
  fieldset[disabled]
  .legacy .buttonstyle:active,
  fieldset[disabled]
  .legacy .buttonstyle.active,
  .legacy input[type="button"].disabled:hover,
  .legacy input[type="button"].disabled:focus,
  .legacy input[type="button"].disabled:active,
  .legacy input[type="button"].disabled.active,
  .legacy input[type="button"][disabled]:hover,
  .legacy input[type="button"][disabled]:focus,
  .legacy input[type="button"][disabled]:active,
  .legacy input[type="button"][disabled].active,
  fieldset[disabled]
  .legacy input[type="button"]:hover,
  fieldset[disabled]
  .legacy input[type="button"]:focus,
  fieldset[disabled]
  .legacy input[type="button"]:active,
  fieldset[disabled]
  .legacy input[type="button"].active {
    color: #fff;
    background-color: #b7b7b7;
    border-color: #b7b7b7; }
  .legacy button svg,
  .legacy .button svg,
  .legacy .buttonstyle svg,
  .legacy input[type="button"] svg {
    -webkit-transition: fill 0.3s;
    transition: fill 0.3s; }
  .legacy button.secondary,
  .legacy .button.secondary,
  .legacy .buttonstyle.secondary,
  .legacy input[type="button"].secondary {
    color: #0056a2;
    text-shadow: none;
    background-color: #fff;
    border-color: #0056a2;
    max-width: 200px; }
    .legacy button.secondary:hover, .legacy button.secondary:focus,
    .legacy .button.secondary:hover,
    .legacy .button.secondary:focus,
    .legacy .buttonstyle.secondary:hover,
    .legacy .buttonstyle.secondary:focus,
    .legacy input[type="button"].secondary:hover,
    .legacy input[type="button"].secondary:focus {
      background-color: #f2fbfe;
      border-color: #0056a2; }
    .legacy button.secondary:active, .legacy button.secondary.active,
    .legacy .button.secondary:active,
    .legacy .button.secondary.active,
    .legacy .buttonstyle.secondary:active,
    .legacy .buttonstyle.secondary.active,
    .legacy input[type="button"].secondary:active,
    .legacy input[type="button"].secondary.active {
      background-color: #e5eef6; }
    .legacy button.secondary.disabled:hover, .legacy button.secondary.disabled:focus, .legacy button.secondary.disabled:active, .legacy button.secondary.disabled.active, .legacy button.secondary[disabled]:hover, .legacy button.secondary[disabled]:focus, .legacy button.secondary[disabled]:active, .legacy button.secondary[disabled].active,
    fieldset[disabled] .legacy button.secondary:hover,
    fieldset[disabled] .legacy button.secondary:focus,
    fieldset[disabled] .legacy button.secondary:active,
    fieldset[disabled] .legacy button.secondary.active,
    .legacy .button.secondary.disabled:hover,
    .legacy .button.secondary.disabled:focus,
    .legacy .button.secondary.disabled:active,
    .legacy .button.secondary.disabled.active,
    .legacy .button.secondary[disabled]:hover,
    .legacy .button.secondary[disabled]:focus,
    .legacy .button.secondary[disabled]:active,
    .legacy .button.secondary[disabled].active,
    fieldset[disabled]
    .legacy .button.secondary:hover,
    fieldset[disabled]
    .legacy .button.secondary:focus,
    fieldset[disabled]
    .legacy .button.secondary:active,
    fieldset[disabled]
    .legacy .button.secondary.active,
    .legacy .buttonstyle.secondary.disabled:hover,
    .legacy .buttonstyle.secondary.disabled:focus,
    .legacy .buttonstyle.secondary.disabled:active,
    .legacy .buttonstyle.secondary.disabled.active,
    .legacy .buttonstyle.secondary[disabled]:hover,
    .legacy .buttonstyle.secondary[disabled]:focus,
    .legacy .buttonstyle.secondary[disabled]:active,
    .legacy .buttonstyle.secondary[disabled].active,
    fieldset[disabled]
    .legacy .buttonstyle.secondary:hover,
    fieldset[disabled]
    .legacy .buttonstyle.secondary:focus,
    fieldset[disabled]
    .legacy .buttonstyle.secondary:active,
    fieldset[disabled]
    .legacy .buttonstyle.secondary.active,
    .legacy input[type="button"].secondary.disabled:hover,
    .legacy input[type="button"].secondary.disabled:focus,
    .legacy input[type="button"].secondary.disabled:active,
    .legacy input[type="button"].secondary.disabled.active,
    .legacy input[type="button"].secondary[disabled]:hover,
    .legacy input[type="button"].secondary[disabled]:focus,
    .legacy input[type="button"].secondary[disabled]:active,
    .legacy input[type="button"].secondary[disabled].active,
    fieldset[disabled]
    .legacy input[type="button"].secondary:hover,
    fieldset[disabled]
    .legacy input[type="button"].secondary:focus,
    fieldset[disabled]
    .legacy input[type="button"].secondary:active,
    fieldset[disabled]
    .legacy input[type="button"].secondary.active {
      color: #0056a2;
      background-color: #fff;
      border-color: #0056a2; }
  .legacy button.tertiary,
  .legacy .button.tertiary,
  .legacy .buttonstyle.tertiary,
  .legacy input[type="button"].tertiary {
    color: #0056a2;
    text-shadow: none;
    background-color: #fff;
    border: none;
    max-width: 200px; }
    .legacy button.tertiary:hover, .legacy button.tertiary:focus,
    .legacy .button.tertiary:hover,
    .legacy .button.tertiary:focus,
    .legacy .buttonstyle.tertiary:hover,
    .legacy .buttonstyle.tertiary:focus,
    .legacy input[type="button"].tertiary:hover,
    .legacy input[type="button"].tertiary:focus {
      text-decoration: underline; }
    .legacy button.tertiary:active, .legacy button.tertiary.active,
    .legacy .button.tertiary:active,
    .legacy .button.tertiary.active,
    .legacy .buttonstyle.tertiary:active,
    .legacy .buttonstyle.tertiary.active,
    .legacy input[type="button"].tertiary:active,
    .legacy input[type="button"].tertiary.active {
      color: #1d3557; }
    .legacy button.tertiary.disabled:hover, .legacy button.tertiary.disabled:focus, .legacy button.tertiary.disabled:active, .legacy button.tertiary.disabled.active, .legacy button.tertiary[disabled]:hover, .legacy button.tertiary[disabled]:focus, .legacy button.tertiary[disabled]:active, .legacy button.tertiary[disabled].active,
    fieldset[disabled] .legacy button.tertiary:hover,
    fieldset[disabled] .legacy button.tertiary:focus,
    fieldset[disabled] .legacy button.tertiary:active,
    fieldset[disabled] .legacy button.tertiary.active,
    .legacy .button.tertiary.disabled:hover,
    .legacy .button.tertiary.disabled:focus,
    .legacy .button.tertiary.disabled:active,
    .legacy .button.tertiary.disabled.active,
    .legacy .button.tertiary[disabled]:hover,
    .legacy .button.tertiary[disabled]:focus,
    .legacy .button.tertiary[disabled]:active,
    .legacy .button.tertiary[disabled].active,
    fieldset[disabled]
    .legacy .button.tertiary:hover,
    fieldset[disabled]
    .legacy .button.tertiary:focus,
    fieldset[disabled]
    .legacy .button.tertiary:active,
    fieldset[disabled]
    .legacy .button.tertiary.active,
    .legacy .buttonstyle.tertiary.disabled:hover,
    .legacy .buttonstyle.tertiary.disabled:focus,
    .legacy .buttonstyle.tertiary.disabled:active,
    .legacy .buttonstyle.tertiary.disabled.active,
    .legacy .buttonstyle.tertiary[disabled]:hover,
    .legacy .buttonstyle.tertiary[disabled]:focus,
    .legacy .buttonstyle.tertiary[disabled]:active,
    .legacy .buttonstyle.tertiary[disabled].active,
    fieldset[disabled]
    .legacy .buttonstyle.tertiary:hover,
    fieldset[disabled]
    .legacy .buttonstyle.tertiary:focus,
    fieldset[disabled]
    .legacy .buttonstyle.tertiary:active,
    fieldset[disabled]
    .legacy .buttonstyle.tertiary.active,
    .legacy input[type="button"].tertiary.disabled:hover,
    .legacy input[type="button"].tertiary.disabled:focus,
    .legacy input[type="button"].tertiary.disabled:active,
    .legacy input[type="button"].tertiary.disabled.active,
    .legacy input[type="button"].tertiary[disabled]:hover,
    .legacy input[type="button"].tertiary[disabled]:focus,
    .legacy input[type="button"].tertiary[disabled]:active,
    .legacy input[type="button"].tertiary[disabled].active,
    fieldset[disabled]
    .legacy input[type="button"].tertiary:hover,
    fieldset[disabled]
    .legacy input[type="button"].tertiary:focus,
    fieldset[disabled]
    .legacy input[type="button"].tertiary:active,
    fieldset[disabled]
    .legacy input[type="button"].tertiary.active {
      color: #b7b7b7; }
    .legacy button.tertiary i,
    .legacy .button.tertiary i,
    .legacy .buttonstyle.tertiary i,
    .legacy input[type="button"].tertiary i {
      display: inline-block;
      min-width: 10px;
      margin-bottom: -.1rem; }
  .legacy button.quaternary,
  .legacy .button.quaternary,
  .legacy .buttonstyle.quaternary,
  .legacy input[type="button"].quaternary {
    line-height: 1em;
    padding: 0.8125rem 24px;
    min-height: 2.5rem;
    color: #0056a2;
    text-shadow: none;
    background-color: #fff;
    border-color: #0056a2;
    max-width: 280px; }
    .legacy button.quaternary:hover, .legacy button.quaternary:focus, .legacy button.quaternary:active, .legacy button.quaternary.active,
    .legacy .button.quaternary:hover,
    .legacy .button.quaternary:focus,
    .legacy .button.quaternary:active,
    .legacy .button.quaternary.active,
    .legacy .buttonstyle.quaternary:hover,
    .legacy .buttonstyle.quaternary:focus,
    .legacy .buttonstyle.quaternary:active,
    .legacy .buttonstyle.quaternary.active,
    .legacy input[type="button"].quaternary:hover,
    .legacy input[type="button"].quaternary:focus,
    .legacy input[type="button"].quaternary:active,
    .legacy input[type="button"].quaternary.active {
      color: #fff;
      background-color: #ff3a65;
      border-color: #ff3a65; }
    .legacy button.quaternary.disabled:hover, .legacy button.quaternary.disabled:focus, .legacy button.quaternary.disabled:active, .legacy button.quaternary.disabled.active, .legacy button.quaternary[disabled]:hover, .legacy button.quaternary[disabled]:focus, .legacy button.quaternary[disabled]:active, .legacy button.quaternary[disabled].active,
    fieldset[disabled] .legacy button.quaternary:hover,
    fieldset[disabled] .legacy button.quaternary:focus,
    fieldset[disabled] .legacy button.quaternary:active,
    fieldset[disabled] .legacy button.quaternary.active,
    .legacy .button.quaternary.disabled:hover,
    .legacy .button.quaternary.disabled:focus,
    .legacy .button.quaternary.disabled:active,
    .legacy .button.quaternary.disabled.active,
    .legacy .button.quaternary[disabled]:hover,
    .legacy .button.quaternary[disabled]:focus,
    .legacy .button.quaternary[disabled]:active,
    .legacy .button.quaternary[disabled].active,
    fieldset[disabled]
    .legacy .button.quaternary:hover,
    fieldset[disabled]
    .legacy .button.quaternary:focus,
    fieldset[disabled]
    .legacy .button.quaternary:active,
    fieldset[disabled]
    .legacy .button.quaternary.active,
    .legacy .buttonstyle.quaternary.disabled:hover,
    .legacy .buttonstyle.quaternary.disabled:focus,
    .legacy .buttonstyle.quaternary.disabled:active,
    .legacy .buttonstyle.quaternary.disabled.active,
    .legacy .buttonstyle.quaternary[disabled]:hover,
    .legacy .buttonstyle.quaternary[disabled]:focus,
    .legacy .buttonstyle.quaternary[disabled]:active,
    .legacy .buttonstyle.quaternary[disabled].active,
    fieldset[disabled]
    .legacy .buttonstyle.quaternary:hover,
    fieldset[disabled]
    .legacy .buttonstyle.quaternary:focus,
    fieldset[disabled]
    .legacy .buttonstyle.quaternary:active,
    fieldset[disabled]
    .legacy .buttonstyle.quaternary.active,
    .legacy input[type="button"].quaternary.disabled:hover,
    .legacy input[type="button"].quaternary.disabled:focus,
    .legacy input[type="button"].quaternary.disabled:active,
    .legacy input[type="button"].quaternary.disabled.active,
    .legacy input[type="button"].quaternary[disabled]:hover,
    .legacy input[type="button"].quaternary[disabled]:focus,
    .legacy input[type="button"].quaternary[disabled]:active,
    .legacy input[type="button"].quaternary[disabled].active,
    fieldset[disabled]
    .legacy input[type="button"].quaternary:hover,
    fieldset[disabled]
    .legacy input[type="button"].quaternary:focus,
    fieldset[disabled]
    .legacy input[type="button"].quaternary:active,
    fieldset[disabled]
    .legacy input[type="button"].quaternary.active {
      color: #0056a2;
      background-color: #fff;
      border-color: #0056a2; }
  .legacy button.error,
  .legacy .button.error,
  .legacy .buttonstyle.error,
  .legacy input[type="button"].error {
    color: #cf0a2c;
    text-shadow: none;
    background-color: #fff;
    border-color: #cf0a2c; }
    .legacy button.error:hover, .legacy button.error:focus, .legacy button.error:active, .legacy button.error.active,
    .legacy .button.error:hover,
    .legacy .button.error:focus,
    .legacy .button.error:active,
    .legacy .button.error.active,
    .legacy .buttonstyle.error:hover,
    .legacy .buttonstyle.error:focus,
    .legacy .buttonstyle.error:active,
    .legacy .buttonstyle.error.active,
    .legacy input[type="button"].error:hover,
    .legacy input[type="button"].error:focus,
    .legacy input[type="button"].error:active,
    .legacy input[type="button"].error.active {
      color: #fff;
      background-color: #cf0a2c;
      border-color: #cf0a2c; }
    .legacy button.error.disabled:hover, .legacy button.error.disabled:focus, .legacy button.error.disabled:active, .legacy button.error.disabled.active, .legacy button.error[disabled]:hover, .legacy button.error[disabled]:focus, .legacy button.error[disabled]:active, .legacy button.error[disabled].active,
    fieldset[disabled] .legacy button.error:hover,
    fieldset[disabled] .legacy button.error:focus,
    fieldset[disabled] .legacy button.error:active,
    fieldset[disabled] .legacy button.error.active,
    .legacy .button.error.disabled:hover,
    .legacy .button.error.disabled:focus,
    .legacy .button.error.disabled:active,
    .legacy .button.error.disabled.active,
    .legacy .button.error[disabled]:hover,
    .legacy .button.error[disabled]:focus,
    .legacy .button.error[disabled]:active,
    .legacy .button.error[disabled].active,
    fieldset[disabled]
    .legacy .button.error:hover,
    fieldset[disabled]
    .legacy .button.error:focus,
    fieldset[disabled]
    .legacy .button.error:active,
    fieldset[disabled]
    .legacy .button.error.active,
    .legacy .buttonstyle.error.disabled:hover,
    .legacy .buttonstyle.error.disabled:focus,
    .legacy .buttonstyle.error.disabled:active,
    .legacy .buttonstyle.error.disabled.active,
    .legacy .buttonstyle.error[disabled]:hover,
    .legacy .buttonstyle.error[disabled]:focus,
    .legacy .buttonstyle.error[disabled]:active,
    .legacy .buttonstyle.error[disabled].active,
    fieldset[disabled]
    .legacy .buttonstyle.error:hover,
    fieldset[disabled]
    .legacy .buttonstyle.error:focus,
    fieldset[disabled]
    .legacy .buttonstyle.error:active,
    fieldset[disabled]
    .legacy .buttonstyle.error.active,
    .legacy input[type="button"].error.disabled:hover,
    .legacy input[type="button"].error.disabled:focus,
    .legacy input[type="button"].error.disabled:active,
    .legacy input[type="button"].error.disabled.active,
    .legacy input[type="button"].error[disabled]:hover,
    .legacy input[type="button"].error[disabled]:focus,
    .legacy input[type="button"].error[disabled]:active,
    .legacy input[type="button"].error[disabled].active,
    fieldset[disabled]
    .legacy input[type="button"].error:hover,
    fieldset[disabled]
    .legacy input[type="button"].error:focus,
    fieldset[disabled]
    .legacy input[type="button"].error:active,
    fieldset[disabled]
    .legacy input[type="button"].error.active {
      color: #cf0a2c;
      background-color: #fff;
      border-color: #cf0a2c; }
  .legacy button.medium,
  .legacy .button.medium,
  .legacy .buttonstyle.medium,
  .legacy input[type="button"].medium {
    font-weight: 700;
    font-size: 14px;
    font-size: 0.875rem;
    padding: 12px; }
  .legacy button.large,
  .legacy .button.large,
  .legacy .buttonstyle.large,
  .legacy input[type="button"].large {
    font-weight: 700;
    font-size: 16px;
    font-size: 1rem;
    padding: 14px;
    gap: 12px; }
  .legacy button.short,
  .legacy .button.short,
  .legacy .buttonstyle.short,
  .legacy input[type="button"].short {
    line-height: 1em;
    padding: 0.625rem 24px;
    min-height: 2.125rem;
    font-family: SofiaPro, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-size: 1rem;
    max-width: none; }
  .legacy button.pill,
  .legacy .button.pill,
  .legacy .buttonstyle.pill,
  .legacy input[type="button"].pill {
    padding: 10px;
    min-height: auto;
    font-size: 0.8rem;
    max-width: 190px; }
  .legacy button.center,
  .legacy .button.center,
  .legacy .buttonstyle.center,
  .legacy input[type="button"].center {
    display: block;
    margin: 0 auto; }
  .legacy button.full-width,
  .legacy .button.full-width,
  .legacy .buttonstyle.full-width,
  .legacy input[type="button"].full-width {
    width: 100%;
    max-width: 320px; }
  .legacy button.simple,
  .legacy .button.simple,
  .legacy .buttonstyle.simple,
  .legacy input[type="button"].simple {
    background-color: #fff;
    color: #0056a2;
    text-shadow: none; }
  .legacy button a,
  .legacy .button a,
  .legacy .buttonstyle a,
  .legacy input[type="button"] a {
    color: #fff;
    text-decoration: none; }
    .legacy button a:hover,
    .legacy .button a:hover,
    .legacy .buttonstyle a:hover,
    .legacy input[type="button"] a:hover {
      color: #fff;
      text-decoration: none; }
  .legacy button i.left,
  .legacy .button i.left,
  .legacy .buttonstyle i.left,
  .legacy input[type="button"] i.left {
    margin-right: 0.6em; }
  .legacy button i.right,
  .legacy .button i.right,
  .legacy .buttonstyle i.right,
  .legacy input[type="button"] i.right {
    margin-left: 0.6em; }

.legacy .button-fancy-large {
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  padding: 14px;
  gap: 12px; }

.legacy .button-text {
  color: #0056a2;
  text-shadow: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  height: auto;
  min-height: 0;
  width: auto;
  max-width: none;
  text-decoration: none;
  text-transform: inherit;
  background-color: transparent;
  border-width: 0;
  padding: 0;
  -webkit-appearance: inherit; }
  .legacy .button-text:hover, .legacy .button-text:active, .legacy .button-text:focus {
    color: #0056a2;
    background-color: transparent;
    text-decoration: underline; }
  p .legacy .button-text {
    color: #0056a2; }
    p .legacy .button-text:hover, p .legacy .button-text:active, p .legacy .button-text:focus {
      color: #0056a2; }

.legacy .button-full-width {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  width: 100%; }

.legacy .outline-button {
  background: white;
  border-color: #0056a2;
  color: #0056a2;
  text-shadow: none; }
  .legacy .outline-button:hover, .legacy .outline-button:focus {
    text-shadow: 1px 1px #e62144, -1px -1px #e62144, -1px 1px #e62144, 1px -1px #e62144; }

.legacy .fb-button {
  display: inline;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
  background-color: #4c69ba;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#4c69ba), to(#3b55a0));
  background-image: linear-gradient(#4c69ba, #3b55a0);
  font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;
  text-shadow: 0 -1px 0 #354c8c;
  text-decoration: none; }
  .legacy .fb-button::before {
    content: "";
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
    border-right: #364e92 1px solid;
    background: url("../images/fb-logo.png") 6px 10px no-repeat; }
  .legacy .fb-button.fb-permissions {
    float: left;
    margin: 0 5px 0.2em; }

/* Import the base jQuery UI styles */
.ui-helper-hidden {
  display: none; }
  .ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none; }

.ui-helper-clearfix::before, .ui-helper-clearfix::after {
  content: "";
  display: table;
  border-collapse: collapse; }

.ui-helper-clearfix::after {
  clear: both; }

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0; }

[class^="ui-"], [class^="ui-"]::after, [class^="ui-"]::before {
  -webkit-box-sizing: content-box;
          box-sizing: content-box; }

.ui-widget {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400; }
  .ui-widget-content {
    border: 1px solid #ddd;
    background: #fff;
    color: #000; }
    .ui-widget-content a {
      color: #000; }
  .ui-widget-header {
    border: 1px solid #ddd;
    color: #000;
    font-weight: 600;
    background: #e9e9e9; }
    .ui-widget-header a {
      color: #000; }
  .ui-widget-overlay {
    background: #000;
    opacity: 0.75;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 1px solid #dad55e;
  background: #fffa90;
  color: #777620;
  font-weight: normal; }
  .ui-state-highlight, .ui-state-highlight:active, .ui-state-highlight:hover, .ui-state-highlight:focus,
  .ui-state-highlight a,
  .ui-state-highlight a:active,
  .ui-state-highlight a:hover,
  .ui-state-highlight a:focus,
  .ui-widget-content .ui-state-highlight,
  .ui-widget-content .ui-state-highlight:active,
  .ui-widget-content .ui-state-highlight:hover,
  .ui-widget-content .ui-state-highlight:focus,
  .ui-widget-content .ui-state-highlight a,
  .ui-widget-content .ui-state-highlight a:active,
  .ui-widget-content .ui-state-highlight a:hover,
  .ui-widget-content .ui-state-highlight a:focus,
  .ui-widget-header .ui-state-highlight,
  .ui-widget-header .ui-state-highlight:active,
  .ui-widget-header .ui-state-highlight:hover,
  .ui-widget-header .ui-state-highlight:focus,
  .ui-widget-header .ui-state-highlight a,
  .ui-widget-header .ui-state-highlight a:active,
  .ui-widget-header .ui-state-highlight a:hover,
  .ui-widget-header .ui-state-highlight a:focus {
    color: #777620;
    text-decoration: none; }

.ui-state-highlight-text,
.ui-widget-content .ui-state-highlight-text,
.ui-widget-header .ui-state-highlight-text {
  color: #777620; }

.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
  border: 1px solid #f1a899;
  background: #fddfdf;
  color: #5f3f3f;
  font-weight: normal; }
  .ui-state-error, .ui-state-error:active, .ui-state-error:hover, .ui-state-error:focus,
  .ui-state-error a,
  .ui-state-error a:active,
  .ui-state-error a:hover,
  .ui-state-error a:focus,
  .ui-widget-content .ui-state-error,
  .ui-widget-content .ui-state-error:active,
  .ui-widget-content .ui-state-error:hover,
  .ui-widget-content .ui-state-error:focus,
  .ui-widget-content .ui-state-error a,
  .ui-widget-content .ui-state-error a:active,
  .ui-widget-content .ui-state-error a:hover,
  .ui-widget-content .ui-state-error a:focus,
  .ui-widget-header .ui-state-error,
  .ui-widget-header .ui-state-error:active,
  .ui-widget-header .ui-state-error:hover,
  .ui-widget-header .ui-state-error:focus,
  .ui-widget-header .ui-state-error a,
  .ui-widget-header .ui-state-error a:active,
  .ui-widget-header .ui-state-error a:hover,
  .ui-widget-header .ui-state-error a:focus {
    color: #5f3f3f;
    text-decoration: none; }

.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
  color: #5f3f3f; }

.ui-state-disabled {
  cursor: default !important;
  pointer-events: none; }
  .ui-state-disabled,
  .ui-widget-content .ui-state-disabled,
  .ui-widget-header .ui-state-disabled {
    opacity: 0.35;
    background-image: none; }

.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
  font-weight: bold; }

.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
  opacity: 0.5;
  font-weight: normal; }

.ui-corner-all {
  border-radius: 0; }

.ui-corner-top {
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.ui-corner-right {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.ui-corner-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.ui-corner-left {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.ui-corner-tl {
  border-top-left-radius: 0; }

.ui-corner-tr {
  border-top-right-radius: 0; }

.ui-corner-bl {
  border-bottom-left-radius: 0; }

.ui-corner-br {
  border-bottom-right-radius: 0; }

.ui-draggable-handle {
  -ms-touch-action: none;
      touch-action: none; }

.ui-resizable {
  position: relative; }
  .ui-resizable-handle {
    position: absolute;
    font-size: 0.1px;
    display: block;
    -ms-touch-action: none;
        touch-action: none; }
  .ui-resizable-disabled .ui-resizable-handle,
  .ui-resizable-autohide .ui-resizable-handle {
    display: none; }
  .ui-resizable-n {
    cursor: n-resize;
    height: 7px;
    width: 100%;
    top: -5px;
    left: 0; }
  .ui-resizable-s {
    cursor: s-resize;
    height: 7px;
    width: 100%;
    bottom: -5px;
    left: 0; }
  .ui-resizable-e {
    cursor: e-resize;
    width: 7px;
    right: -5px;
    top: 0;
    height: 100%; }
  .ui-resizable-w {
    cursor: w-resize;
    width: 7px;
    left: -5px;
    top: 0;
    height: 100%; }
  .ui-resizable-se {
    cursor: se-resize;
    width: 16px;
    height: 20px;
    right: 1px;
    bottom: 1px; }
  .ui-resizable-sw {
    cursor: sw-resize;
    width: 9px;
    height: 9px;
    left: -5px;
    bottom: -5px; }
  .ui-resizable-nw {
    cursor: nw-resize;
    width: 9px;
    height: 9px;
    left: -5px;
    top: -5px; }
  .ui-resizable-ne {
    cursor: ne-resize;
    width: 9px;
    height: 9px;
    right: -5px;
    top: -5px; }

.ui-selectable {
  -ms-touch-action: none;
      touch-action: none; }
  .ui-selectable-helper {
    position: absolute;
    z-index: 100;
    border: 1px dotted #333; }

.ui-sortable-handle {
  -ms-touch-action: none;
      touch-action: none; }

/* Please uncomment any features that are needed for the site */
.ui-accordion {
  background: #fff;
  margin: 0 auto;
  border: 1px solid #686868;
  border-top: none; }
  .ui-accordion-header {
    cursor: pointer;
    background: #fff;
    margin: 0;
    border: none;
    border-top: 1px solid #686868; }
    .ui-accordion-header .plus-expander {
      height: 18px;
      width: 18px;
      padding: 0 0 0 6px;
      float: right;
      position: relative; }
    .ui-accordion-header .plus-expander::before,
    .ui-accordion-header .plus-expander::after {
      content: "";
      position: absolute;
      width: 18px;
      height: 4px;
      background: #686868;
      border: 0 solid #000;
      border-radius: 4px;
      display: block;
      top: 12px;
      left: 0;
      -webkit-transition: -webkit-transform 0.5s 0s;
      transition: -webkit-transform 0.5s 0s;
      transition: transform 0.5s 0s;
      transition: transform 0.5s 0s, -webkit-transform 0.5s 0s;
      outline: none;
      -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg); }
    .ui-accordion-header .plus-expander::after {
      -webkit-transform: rotate(-270deg);
              transform: rotate(-270deg); }
    .ui-accordion-header-active {
      border-bottom: 1px solid #686868; }
      .ui-accordion-header-active .plus-expander::after,
      .ui-accordion-header-active .plus-expander::before {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg); }
  .ui-accordion-content {
    display: none;
    padding: 0;
    border: none;
    padding: .5em 0;
    margin: 0; }
  .ui-accordion.auto-init-accordion * {
    -webkit-box-sizing: content-box;
            box-sizing: content-box; }

.gradient-accordion {
  border: none;
  border-bottom: 1px solid #686868;
  -o-border-image: linear-gradient(to left, #fff, #686868, #686868, #fff) 1 1;
     border-image: -webkit-gradient(linear, right top, left top, from(#fff), color-stop(#686868), color-stop(#686868), to(#fff)) 1 1;
     border-image: linear-gradient(to left, #fff, #686868, #686868, #fff) 1 1;
  color: #686868; }
  .gradient-accordion .ui-accordion-header {
    -o-border-image: linear-gradient(to left, #fff, #686868, #686868, #fff) 1 1;
       border-image: -webkit-gradient(linear, right top, left top, from(#fff), color-stop(#686868), color-stop(#686868), to(#fff)) 1 1;
       border-image: linear-gradient(to left, #fff, #686868, #686868, #fff) 1 1;
    height: 38px;
    line-height: 38px;
    padding: 5px 0;
    font-size: 1.2em;
    outline: none; }
    .gradient-accordion .ui-accordion-header.ui-state-active {
      background: radial-gradient(circle, #f3f3f3, #fff);
      border-bottom: 1px solid #686868; }
    .gradient-accordion .ui-accordion-header.ui-state-focus {
      background: radial-gradient(circle, #ececec, #fff); }

.no-gradient-accordion {
  border: none;
  border-bottom: 1px solid #686868;
  color: #686868; }
  .no-gradient-accordion .ui-accordion-header {
    height: 38px;
    line-height: 38px;
    padding: 5px;
    font-size: 1.2em;
    outline: none; }
    .no-gradient-accordion .ui-accordion-header.ui-state-active {
      background: #f3f3f3;
      border-bottom: 1px solid #686868; }
    .no-gradient-accordion .ui-accordion-header.ui-state-focus {
      background: #f3f3f3; }

.ui-dialog {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  outline: 0;
  z-index: 141 !important; }
  .ui-dialog-titlebar {
    padding: 6px 12px;
    position: relative;
    border-radius: 0;
    border-width: 0;
    background: transparent;
    margin: 0;
    color: #0056a2;
    border-color: #ddd; }
    .ui-dialog-titlebar-close {
      position: absolute;
      right: 6px;
      top: calc(50% - 11px);
      width: 20px;
      height: 20px;
      padding: 1px; }
  .ui-dialog-title {
    float: left;
    width: calc(100% - 32px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .ui-dialog-content {
    position: relative;
    border: 0;
    padding: 15px;
    background: none;
    overflow: auto; }
  .ui-dialog-buttonpane {
    text-align: left;
    border-width: 0;
    background-image: none;
    padding: 0 0 0; }
    .ui-dialog-buttonpane .ui-dialog-buttonset {
      float: right; }
    .ui-dialog-buttonpane button {
      cursor: pointer; }
      .ui-dialog-buttonpane button:not(:last-child) {
        margin-right: 10px; }

.ui-draggable .ui-dialog-titlebar {
  cursor: move; }

.ui-tooltip {
  padding: 10px;
  position: absolute;
  z-index: 600 !important;
  max-width: 300px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  color: #000; }

/* Build-A-Bear Overrides */
.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat; }

.ui-widget {
  font-family: SofiaPro, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem; }

.ui-widget-overlay {
  z-index: 140; }
  .ui-widget-overlay + .ui-widget-overlay {
    z-index: 141; }
  .ui-widget-overlay + .ui-dialog {
    z-index: 142 !important; }

.ui-widget-content a.button {
  color: #fff; }

.ui-dialog {
  border: none;
  z-index: 141 !important; }
  .ui-dialog + .ui-dialog {
    z-index: 142 !important; }
  .ui-dialog-titlebar {
    padding: 23px 52px 25px 20px; }
  .ui-dialog-title {
    font-weight: 700;
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 1em; }
    @media only screen and (min-width: 768px) {
      .ui-dialog-title {
        font-weight: 700;
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 1em; } }
    @media only screen and (min-width: 768px) {
      .ui-dialog-title {
        line-height: 1.3em; } }
  .ui-dialog .ui-icon-closethick {
    background-image: url("../images/svg-icons/close.svg");
    background-position: left top;
    left: 0;
    top: 0;
    margin-left: 0;
    margin-top: 0; }
  .ui-dialog-titlebar-close.ui-button {
    top: 14px;
    right: 20px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
    min-width: 0;
    min-height: 0; }
    .ui-dialog-titlebar-close.ui-button span {
      margin: 0;
      padding: 0; }
    .ui-dialog-titlebar-close.ui-button .ui-button-text {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
  .ui-dialog-content {
    position: static;
    padding: 0 20px 30px; }
    .ui-dialog-content::before {
      content: "";
      display: block;
      height: 0;
      width: 100%;
      border-top: 1px solid #bfebfa; }
  .ui-dialog.has-banner .ui-dialog-titlebar {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: absolute;
    width: 100%; }
  .ui-dialog.has-banner .ui-dialog-content {
    padding: 0;
    background: #fff; }
    .ui-dialog.has-banner .ui-dialog-content::before {
      content: none; }
    .ui-dialog.has-banner .ui-dialog-content .dialog-banner {
      width: 100%; }
  .ui-dialog.light-titlebar .ui-dialog-title {
    color: #fff; }
  .ui-dialog.light-titlebar .ui-icon-closethick {
    background-image: url("../images/svg-icons/close-light.svg"); }
  .ui-dialog h1 h2 {
    font-size: 1.8em;
    font-style: italic;
    font-weight: 400;
    margin: 5px 158px 5px 0; }
  .ui-dialog .dialog-required {
    font-weight: 400;
    position: relative;
    text-transform: none; }
  .ui-dialog-buttonpane {
    padding: 0 20px 30px; }
  .ui-dialog-buttonset button.ui-button {
    cursor: pointer;
    display: inline-block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: auto;
    padding: 8px;
    gap: 8px;
    font-family: SofiaPro, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    font-size: 0.75rem;
    letter-spacing: normal;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    min-width: 80px;
    color: #fff;
    background-color: #e62144;
    border-color: #e62144;
    max-width: 286px; }
    .ui-dialog-buttonset button.ui-button:hover, .ui-dialog-buttonset button.ui-button:focus, .ui-dialog-buttonset button.ui-button:active, .ui-dialog-buttonset button.ui-button.active {
      text-decoration: none; }
    .ui-dialog-buttonset button.ui-button:focus {
      outline: -webkit-focus-ring-color auto 5px; }
    .ui-dialog-buttonset button.ui-button:active {
      cursor: default;
      background-color: #a5001d;
      border-color: #a5001d; }
    .ui-dialog-buttonset button.ui-button.disabled, .ui-dialog-buttonset button.ui-button[disabled],
    fieldset[disabled] .ui-dialog-buttonset button.ui-button {
      cursor: default;
      background-color: #b7b7b7;
      border-color: #b7b7b7; }
    .ui-dialog-buttonset button.ui-button:hover, .ui-dialog-buttonset button.ui-button:focus, .ui-dialog-buttonset button.ui-button:active, .ui-dialog-buttonset button.ui-button.active {
      color: #fff;
      background-color: #d00a2d;
      border-color: #d00a2d; }
    .ui-dialog-buttonset button.ui-button.disabled:hover, .ui-dialog-buttonset button.ui-button.disabled:focus, .ui-dialog-buttonset button.ui-button.disabled:active, .ui-dialog-buttonset button.ui-button.disabled.active, .ui-dialog-buttonset button.ui-button[disabled]:hover, .ui-dialog-buttonset button.ui-button[disabled]:focus, .ui-dialog-buttonset button.ui-button[disabled]:active, .ui-dialog-buttonset button.ui-button[disabled].active,
    fieldset[disabled] .ui-dialog-buttonset button.ui-button:hover,
    fieldset[disabled] .ui-dialog-buttonset button.ui-button:focus,
    fieldset[disabled] .ui-dialog-buttonset button.ui-button:active,
    fieldset[disabled] .ui-dialog-buttonset button.ui-button.active {
      color: #fff;
      background-color: #b7b7b7;
      border-color: #b7b7b7; }
    .ui-dialog-buttonset button.ui-button svg {
      -webkit-transition: fill 0.3s;
      transition: fill 0.3s; }
    .ui-dialog-buttonset button.ui-button.secondary {
      color: #0056a2;
      text-shadow: none;
      background-color: #fff;
      border-color: #0056a2;
      max-width: 200px; }
      .ui-dialog-buttonset button.ui-button.secondary:hover, .ui-dialog-buttonset button.ui-button.secondary:focus {
        background-color: #f2fbfe;
        border-color: #0056a2; }
      .ui-dialog-buttonset button.ui-button.secondary:active, .ui-dialog-buttonset button.ui-button.secondary.active {
        background-color: #e5eef6; }
      .ui-dialog-buttonset button.ui-button.secondary.disabled:hover, .ui-dialog-buttonset button.ui-button.secondary.disabled:focus, .ui-dialog-buttonset button.ui-button.secondary.disabled:active, .ui-dialog-buttonset button.ui-button.secondary.disabled.active, .ui-dialog-buttonset button.ui-button.secondary[disabled]:hover, .ui-dialog-buttonset button.ui-button.secondary[disabled]:focus, .ui-dialog-buttonset button.ui-button.secondary[disabled]:active, .ui-dialog-buttonset button.ui-button.secondary[disabled].active,
      fieldset[disabled] .ui-dialog-buttonset button.ui-button.secondary:hover,
      fieldset[disabled] .ui-dialog-buttonset button.ui-button.secondary:focus,
      fieldset[disabled] .ui-dialog-buttonset button.ui-button.secondary:active,
      fieldset[disabled] .ui-dialog-buttonset button.ui-button.secondary.active {
        color: #0056a2;
        background-color: #fff;
        border-color: #0056a2; }
    .ui-dialog-buttonset button.ui-button.tertiary {
      color: #0056a2;
      text-shadow: none;
      background-color: #fff;
      border: none;
      max-width: 200px; }
      .ui-dialog-buttonset button.ui-button.tertiary:hover, .ui-dialog-buttonset button.ui-button.tertiary:focus {
        text-decoration: underline; }
      .ui-dialog-buttonset button.ui-button.tertiary:active, .ui-dialog-buttonset button.ui-button.tertiary.active {
        color: #1d3557; }
      .ui-dialog-buttonset button.ui-button.tertiary.disabled:hover, .ui-dialog-buttonset button.ui-button.tertiary.disabled:focus, .ui-dialog-buttonset button.ui-button.tertiary.disabled:active, .ui-dialog-buttonset button.ui-button.tertiary.disabled.active, .ui-dialog-buttonset button.ui-button.tertiary[disabled]:hover, .ui-dialog-buttonset button.ui-button.tertiary[disabled]:focus, .ui-dialog-buttonset button.ui-button.tertiary[disabled]:active, .ui-dialog-buttonset button.ui-button.tertiary[disabled].active,
      fieldset[disabled] .ui-dialog-buttonset button.ui-button.tertiary:hover,
      fieldset[disabled] .ui-dialog-buttonset button.ui-button.tertiary:focus,
      fieldset[disabled] .ui-dialog-buttonset button.ui-button.tertiary:active,
      fieldset[disabled] .ui-dialog-buttonset button.ui-button.tertiary.active {
        color: #b7b7b7; }
      .ui-dialog-buttonset button.ui-button.tertiary i {
        display: inline-block;
        min-width: 10px;
        margin-bottom: -.1rem; }
  @media only screen and (max-width: 767px) {
    .ui-dialog {
      max-width: 95%; }
      .ui-dialog .ui-dialog-content {
        height: auto !important;
        overflow: visible; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .ui-dialog {
      max-width: 98%; }
      .ui-dialog .ui-dialog-content {
        height: auto !important;
        overflow: visible; } }

.no-close .ui-dialog-titlebar-close {
  display: none; }

button.ui-dialog-titlebar-close {
  text-shadow: none;
  overflow: hidden; }

.ui-tabs .ui-tabs-nav {
  height: 2.45em;
  padding: 0; }
  .ui-tabs .ui-tabs-nav li {
    border-color: #fff;
    border-style: solid;
    border-width: 0 0 3px 0; }
    .ui-tabs .ui-tabs-nav li.ui-state-active {
      border-bottom-color: #40a949; }
      .ui-tabs .ui-tabs-nav li.ui-state-active a {
        color: #0056a2;
        font-weight: 700; }
    .ui-tabs .ui-tabs-nav li a {
      color: #0056a2; }

.ui-tabs .ui-tabs-panel {
  background-color: #fff;
  border: 1px solid #fff;
  line-height: 1.5em;
  margin: 0;
  padding: 20px 10px; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .ui-tabs .ui-tabs-panel h3 {
      display: none; } }

.legacy .full-bleed-width img.banner {
  width: 100%; }

.legacy .slot-grid-header p {
  margin: 0;
  padding: 0; }

.legacy .product-listing-1x4 {
  background: none repeat scroll 0 0 #fff;
  overflow: hidden;
  padding: .83em 0; }
  .legacy .product-listing-1x4 .search-result-items .grid-tile {
    margin: 0 1% 0 2%;
    width: 18%; }
    @media only screen and (max-width: 767px) {
      .legacy .product-listing-1x4 .search-result-items .grid-tile {
        margin: 2%;
        width: 92%; } }

.legacy .account-nav-asset {
  background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top transparent;
  line-height: 1.5em;
  margin: 3.33em .83em;
  padding: .83em 0; }
  .legacy .account-nav-asset h2 {
    font-style: italic;
    font-weight: 400;
    margin: 1.16em 0 1.66em; }

.legacy .content-asset p {
  line-height: 1.6em;
  margin: 1em 0; }

.legacy .content-asset address {
  margin: 1em 0; }

.legacy .content-asset .account-options {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0;
  padding: 0; }
  .legacy .content-asset .account-options li {
    /* for non-flex browsers */
    display: inline-block;
    width: 100%;
    vertical-align: top;
    /* for flex browsers */
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    /* end flex styling */
    background-color: #fff;
    border-top: 1px solid #bfebfa;
    border-radius: 5px;
    list-style-type: none;
    text-align: center; }
    .legacy .content-asset .account-options li a {
      display: block;
      float: left;
      text-decoration: none;
      position: relative;
      padding: 20px 0; }
      .legacy .content-asset .account-options li a .icon-wrapper {
        width: 32px;
        float: left; }
        .legacy .content-asset .account-options li a .icon-wrapper span.icon-birthdays-red {
          display: inline-block;
          width: 32px;
          height: 40px;
          margin: 0;
          color: #ed3154;
          background-image: url("../images/rewards/rewards-offers-icon-red.png");
          background-size: 32px;
          background-repeat: no-repeat; }
      .legacy .content-asset .account-options li a svg {
        display: inline-block;
        float: left; }
        .legacy .content-asset .account-options li a svg.gift-box {
          width: 32px;
          height: 32px; }
      .legacy .content-asset .account-options li a span {
        display: inline-block;
        font-weight: 700;
        font-size: 24px;
        font-size: 1.5rem;
        line-height: 1em;
        color: #0056a2;
        font-weight: 600;
        margin-left: 25px;
        position: relative;
        top: 7px;
        float: left; }
        @media only screen and (min-width: 768px) {
          .legacy .content-asset .account-options li a span {
            font-size: 32px;
            font-size: 2rem;
            line-height: 1em; } }
      .legacy .content-asset .account-options li a:hover {
        color: #0056a2; }
    @media only screen and (min-width: 1025px) {
      .legacy .content-asset .account-options li {
        /* for non-flex browsers */
        width: 50%;
        /* for flex browsers */
        flex-basis: 50%;
        -ms-flex-preferred-size: 49%;
        /* end flex styling */
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        border-radius: 0; }
        .legacy .content-asset .account-options li a {
          padding: 30px 0; }
        .legacy .content-asset .account-options li:nth-child(2) {
          margin-top: 30px; }
        .legacy .content-asset .account-options li:nth-of-type(even) {
          border-left: 1px solid #bfebfa; }
          .legacy .content-asset .account-options li:nth-of-type(even) a {
            padding-left: 22px; } }
    .legacy .content-asset .account-options li:first-child {
      margin-top: 30px; }

.legacy .browser-compatibility-alert {
  background-color: #d00a2d;
  color: #fff5f7;
  font-size: 1.3em;
  line-height: 2em;
  padding: 0.8em; }

.legacy .folder-slot {
  border: 1px solid #88b2d5;
  padding: 0.5em;
  background-color: #fff; }
  .legacy .folder-slot h2 {
    margin: 0.5em 0;
    color: #88b2d5; }

.legacy .menu-slot {
  display: none;
  border-top: 1px solid #bfebfa;
  margin-top: 0.5em;
  padding-top: 1.5em; }
  .legacy .menu-slot .social-links {
    padding-left: 0; }
    .legacy .menu-slot .social-links li {
      display: inline-block;
      padding-left: 0; }
  @media only screen and (min-width: 768px) {
    .legacy .menu-slot {
      width: 13.5rem;
      display: block; } }

.legacy .cart-recommendations h1,
.legacy .category-slot h1,
.legacy .product-slot h1 {
  position: absolute;
  text-align: right;
  bottom: 2%;
  left: 2%;
  color: #fff;
  font-weight: 400;
  font-size: 58px;
  font-size: 3.625rem; }

.legacy .cart-recommendations h2,
.legacy .category-slot h2,
.legacy .product-slot h2 {
  margin: 0;
  padding: .1rem .5rem;
  color: #f28a21;
  font-weight: 400;
  font-size: 29px;
  font-size: 1.8125rem;
  font-style: italic; }

.legacy .cart-recommendations h3,
.legacy .category-slot h3,
.legacy .product-slot h3 {
  bottom: 0;
  margin: 0;
  opacity: 0.6;
  padding: 2rem;
  position: absolute;
  width: 100%;
  background-color: #fff;
  color: #000;
  font-weight: 400;
  font-size: 32px;
  font-size: 2rem;
  text-transform: uppercase; }

.legacy .cart-recommendations .catlanding-banner,
.legacy .category-slot .catlanding-banner,
.legacy .product-slot .catlanding-banner {
  overflow: hidden;
  position: relative;
  width: 100%; }
  .legacy .cart-recommendations .catlanding-banner img,
  .legacy .category-slot .catlanding-banner img,
  .legacy .product-slot .catlanding-banner img {
    height: auto;
    width: 100%; }

.legacy .cart-recommendations .category-tile,
.legacy .category-slot .category-tile,
.legacy .product-slot .category-tile {
  float: left;
  overflow: hidden;
  padding: 0 .15%;
  position: relative;
  width: 100%; }
  @media only screen and (min-width: 768px) {
    .legacy .cart-recommendations .category-tile,
    .legacy .category-slot .category-tile,
    .legacy .product-slot .category-tile {
      width: 33%; } }
  .legacy .cart-recommendations .category-tile img,
  .legacy .category-slot .category-tile img,
  .legacy .product-slot .category-tile img {
    height: auto;
    width: 100%; }

.legacy .cart-recommendations .cat-banner,
.legacy .category-slot .cat-banner,
.legacy .product-slot .cat-banner {
  overflow: hidden;
  position: relative;
  width: 100%; }
  .legacy .cart-recommendations .cat-banner img,
  .legacy .category-slot .cat-banner img,
  .legacy .product-slot .cat-banner img {
    height: auto;
    width: 100%; }
  .legacy .cart-recommendations .cat-banner h1,
  .legacy .category-slot .cat-banner h1,
  .legacy .product-slot .cat-banner h1 {
    color: #0056a2;
    right: 2%; }

.legacy .cart-recommendations .search-result-items .grid-tile,
.legacy .category-slot .search-result-items .grid-tile,
.legacy .product-slot .search-result-items .grid-tile {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin: 0;
  padding: 0 1%;
  width: 49%; }
  @media only screen and (min-width: 768px) {
    .legacy .cart-recommendations .search-result-items .grid-tile,
    .legacy .category-slot .search-result-items .grid-tile,
    .legacy .product-slot .search-result-items .grid-tile {
      width: 33%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; } }
  .legacy .cart-recommendations .search-result-items .grid-tile .product-tile,
  .legacy .category-slot .search-result-items .grid-tile .product-tile,
  .legacy .product-slot .search-result-items .grid-tile .product-tile {
    margin: 0 .15%;
    padding: 1rem 0;
    width: 100%; }
    .legacy .cart-recommendations .search-result-items .grid-tile .product-tile .product-name,
    .legacy .category-slot .search-result-items .grid-tile .product-tile .product-name,
    .legacy .product-slot .search-result-items .grid-tile .product-tile .product-name {
      font-weight: 400;
      font-size: 18px;
      font-size: 1.125rem;
      height: auto;
      margin-top: .5em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
      .legacy .cart-recommendations .search-result-items .grid-tile .product-tile .product-name a,
      .legacy .category-slot .search-result-items .grid-tile .product-tile .product-name a,
      .legacy .product-slot .search-result-items .grid-tile .product-tile .product-name a {
        color: #0056a2; }
    .legacy .cart-recommendations .search-result-items .grid-tile .product-tile .product-pricing,
    .legacy .category-slot .search-result-items .grid-tile .product-tile .product-pricing,
    .legacy .product-slot .search-result-items .grid-tile .product-tile .product-pricing {
      font-weight: 400;
      font-size: 16px;
      font-size: 1rem; }
    .legacy .cart-recommendations .search-result-items .grid-tile .product-tile .product-swatches,
    .legacy .category-slot .search-result-items .grid-tile .product-tile .product-swatches,
    .legacy .product-slot .search-result-items .grid-tile .product-tile .product-swatches {
      display: none; }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .legacy .html-slot-container img {
    max-width: 100%; } }

.legacy .card-asset {
  display: inline-block;
  width: 23%;
  height: 30rem;
  margin: 0 5px;
  border: 1px solid #88b2d5;
  border-radius: 4px;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
  /*
    @include respond(desktop) {
        margin: 0 10px 0 0;
    }
    */ }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .legacy .card-asset {
      width: 80%;
      height: 13rem;
      margin: 10px 5px; } }
  @media only screen and (min-width: 320px) and (max-width: 479px) {
    .legacy .card-asset {
      width: 90%;
      height: 13rem;
      margin: 10px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .legacy .card-asset {
      margin-bottom: 0;
      height: 28rem; } }
  .legacy .card-asset.paws-background {
    background: url("../images/paw-pattern.png");
    background-position: top left;
    background-repeat: repeat;
    /*
        @include respond(768px, 1115px) {
            margin: 0 10% 0 calc((90% - 472px)/2);
        }
        */ }
    @media only screen and (min-width: 768px) and (max-width: 1114px) {
      .legacy .card-asset.paws-background:nth-child(2) {
        margin-bottom: 25px; } }
  @media only screen and (min-width: 768px) and (max-width: 1114px) {
    .legacy .card-asset:nth-child(3) {
      margin-bottom: 25px; } }
  .legacy .card-asset .image {
    width: 45.5%;
    text-align: left;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    .legacy .card-asset .image picture {
      font-size: 0; }
    .legacy .card-asset .image.full {
      height: 210px;
      left: -1px; }
      .legacy .card-asset .image.full img {
        height: 100%; }
        @media only screen and (min-width: 768px) {
          .legacy .card-asset .image.full img {
            height: auto; } }
      @media only screen and (min-width: 768px) {
        .legacy .card-asset .image.full {
          width: 100.5%;
          height: 265px;
          margin-right: 0;
          margin-top: -2px; } }
    @media only screen and (min-width: 768px) {
      .legacy .card-asset .image {
        top: 0;
        -webkit-transform: none;
                transform: none;
        width: 100%;
        margin-right: 17px; }
        .legacy .card-asset .image:last-child {
          margin-right: 0; } }
    @media only screen and (min-width: 768px) {
      .legacy .card-asset .image img {
        width: 100%;
        height: auto; } }
    @media only screen and (min-width: 768px) {
      .legacy .card-asset .image.center {
        height: 265px;
        padding: 25% 0; } }
  .legacy .card-asset .copy {
    width: 49%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 12px;
    text-align: center; }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      .legacy .card-asset .copy {
        right: 5%; } }
    .legacy .card-asset .copy h3 {
      font-size: 16px;
      font-size: 1rem; }
      @media only screen and (min-width: 768px) {
        .legacy .card-asset .copy h3 {
          font-size: 22px;
          font-size: 1.375rem; } }
    .legacy .card-asset .copy p {
      font-family: SofiaPro, Arial, sans-serif;
      font-weight: 400;
      font-size: 12px;
      font-size: 0.75rem;
      padding: 5px; }
    .legacy .card-asset .copy .button {
      margin-top: 10px; }
    @media only screen and (min-width: 768px) {
      .legacy .card-asset .copy {
        width: 100%;
        -webkit-transform: none;
                transform: none;
        bottom: 19px;
        top: auto;
        right: 0;
        left: 0;
        margin: 0 auto; } }
  .legacy .card-asset.card-asset-2 {
    height: 171px; }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      .legacy .card-asset.card-asset-2.paws-background {
        margin: auto; } }
    .legacy .card-asset.card-asset-2 .copy {
      text-align: center;
      right: 9px; }
      @media only screen and (min-width: 480px) and (max-width: 767px) {
        .legacy .card-asset.card-asset-2 .copy {
          right: 15%; } }
      .legacy .card-asset.card-asset-2 .copy img {
        display: block;
        margin: 0 auto; }
      @media only screen and (min-width: 768px) {
        .legacy .card-asset.card-asset-2 .copy {
          width: 46%;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
          text-align: center;
          right: 0;
          left: auto;
          bottom: -50px; } }
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        .legacy .card-asset.card-asset-2 .copy {
          width: 60%; } }
      .legacy .card-asset.card-asset-2 .copy .button {
        margin-top: 0; }
    .legacy .card-asset.card-asset-2 .image {
      vertical-align: top;
      height: 171px;
      left: -2px; }
      .legacy .card-asset.card-asset-2 .image.full img {
        height: 171px; }
        @media only screen and (min-width: 768px) {
          .legacy .card-asset.card-asset-2 .image.full img {
            height: auto; } }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
          .legacy .card-asset.card-asset-2 .image.full img {
            width: auto; } }
      @media only screen and (min-width: 768px) {
        .legacy .card-asset.card-asset-2 .image .center {
          width: 54%;
          height: 324px;
          margin-top: -1px; } }
      @media only screen and (min-width: 768px) {
        .legacy .card-asset.card-asset-2 .image {
          width: 54%;
          height: 324px;
          margin-top: -1px; } }
    @media only screen and (min-width: 768px) {
      .legacy .card-asset.card-asset-2 {
        width: 45%;
        height: 18rem;
        margin: 10px; } }
    .legacy .card-asset.card-asset-2.header-price .copy img {
      margin: 0 auto 20px; }
    .legacy .card-asset.card-asset-2.header-price .copy p {
      margin: 6px 0;
      font-size: 12px;
      font-size: 0.75rem; }
      @media only screen and (min-width: 768px) {
        .legacy .card-asset.card-asset-2.header-price .copy p {
          font-size: 19px;
          font-size: 1.1875rem; } }
    .legacy .card-asset.card-asset-2.header-price .copy .button {
      padding-left: 9px;
      padding-right: 9px; }
      @media only screen and (min-width: 768px) {
        .legacy .card-asset.card-asset-2.header-price .copy .button {
          position: relative;
          top: 22px; } }
    @media only screen and (min-width: 768px) {
      .legacy .card-asset.card-asset-2.header-price .copy h3 {
        font-size: 16px;
        font-size: 1rem; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    .legacy .card-asset.card-asset-2.header-price .copy h3 {
      font-size: 30px;
      font-size: 1.875rem; } }
    .legacy .card-asset.card-asset-2.logo-slogan .copy img {
      position: relative;
      top: 5px; }
    .legacy .card-asset.card-asset-2.logo-slogan .copy p {
      margin: 7px 0 14px;
      font-size: 12px;
      font-size: 0.75rem; }
      @media only screen and (min-width: 768px) {
        .legacy .card-asset.card-asset-2.logo-slogan .copy p {
          font-size: 19px;
          font-size: 1.1875rem; } }
    .legacy .card-asset.card-asset-2.logo-slogan .copy .button {
      padding-left: 12px;
      padding-right: 12px;
      margin-bottom: 13px; }
      @media only screen and (min-width: 768px) {
        .legacy .card-asset.card-asset-2.logo-slogan .copy .button {
          position: relative;
          top: 22px; } }
    @media only screen and (min-width: 1025px) {
      .legacy .card-asset.card-asset-2:last-of-type {
        margin-left: 10px; } }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      .legacy .card-asset.card-asset-2:last-of-type {
        margin-left: 0;
        margin-top: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      .legacy .card-asset.card-asset-2 {
        height: 330px;
        width: 100%; } }
  @media only screen and (min-width: 1025px) {
    .legacy .card-asset:last-of-type {
      margin-right: 0; } }

.legacy #dialog-container .content-asset img {
  max-width: 100%; }

.legacy .quad-carousel {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 auto;
  padding: 0; }
  .legacy .quad-carousel .qc-grid-tile {
    position: relative;
    float: left;
    width: 50%;
    height: auto;
    border-bottom: 1px solid #e5eef6;
    margin: 0;
    padding: 0 20px 30px 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
    .legacy .quad-carousel .qc-grid-tile.new-row {
      border-right: 1px solid #e5eef6; }
    .legacy .quad-carousel .qc-grid-tile .product-tile {
      margin-bottom: 27px; }
    .legacy .quad-carousel .qc-grid-tile .product-set-price {
      display: none; }
    .legacy .quad-carousel .qc-grid-tile .btn-container {
      padding: 0 5px;
      position: absolute;
      bottom: 10px;
      margin: 0 auto;
      left: 0;
      right: 0; }
    @media only screen and (min-width: 1025px) {
      .legacy .quad-carousel .qc-grid-tile {
        width: 25%;
        border-bottom: none;
        padding: 0 2% 20px; }
        .legacy .quad-carousel .qc-grid-tile.new-row {
          border-right: none; } }

.legacy .highlight-holder .grid-col {
  position: relative;
  margin-left: 0; }
  @media only screen and (min-width: 768px) {
    .legacy .highlight-holder .grid-col {
      width: 50%; }
      .legacy .highlight-holder .grid-col:nth-child(2n+1) {
        clear: both; } }

.legacy .highlight-holder img {
  display: block;
  width: 100%; }

.legacy .highlight-holder .grid-col {
  padding: 0 0 10px; }
  .legacy .highlight-holder .grid-col a {
    display: block;
    overflow: visible; }
    .legacy .highlight-holder .grid-col a .button {
      position: relative;
      margin: 10px auto 0;
      width: 100%; }
  @media only screen and (min-width: 768px) {
    .legacy .highlight-holder .grid-col {
      padding: 0 20px; } }
  .legacy .highlight-holder .grid-col.desktop-only {
    display: none; }
    @media only screen and (min-width: 768px) {
      .legacy .highlight-holder .grid-col.desktop-only {
        display: block; } }
  .legacy .highlight-holder .grid-col.mobile-only {
    display: block; }
    @media only screen and (min-width: 768px) {
      .legacy .highlight-holder .grid-col.mobile-only {
        display: none; } }

.legacy .quicklinks-holder {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-top: 10px; }
  .legacy .quicklinks-holder.six-items .grid-col {
    position: relative;
    margin-left: 0; }
    @media only screen and (min-width: 0) and (max-width: 767px) {
      .legacy .quicklinks-holder.six-items .grid-col {
        width: 50%; }
        .legacy .quicklinks-holder.six-items .grid-col:nth-child(2n+1) {
          clear: both; } }
    @media only screen and (min-width: 768px) {
      .legacy .quicklinks-holder.six-items .grid-col {
        width: 16.66667%; }
        .legacy .quicklinks-holder.six-items .grid-col:nth-child(6n+1) {
          clear: both; } }
  .legacy .quicklinks-holder.four-items {
    max-width: 700px;
    margin: 0 auto; }
    .legacy .quicklinks-holder.four-items .grid-col {
      position: relative;
      margin-left: 0; }
      @media only screen and (min-width: 0) and (max-width: 767px) {
        .legacy .quicklinks-holder.four-items .grid-col {
          width: 50%; }
          .legacy .quicklinks-holder.four-items .grid-col:nth-child(2n+1) {
            clear: both; } }
      @media only screen and (min-width: 768px) {
        .legacy .quicklinks-holder.four-items .grid-col {
          width: 25%; }
          .legacy .quicklinks-holder.four-items .grid-col:nth-child(4n+1) {
            clear: both; } }
  .legacy .quicklinks-holder img {
    display: block;
    width: 100%;
    max-width: 220px;
    margin: 0 auto; }
  .legacy .quicklinks-holder .grid-col {
    padding: 20px;
    border-bottom: 1px solid #e5eef6; }
    .legacy .quicklinks-holder .grid-col.new-row {
      border-right: 1px solid #e5eef6; }
      @media only screen and (min-width: 1025px) {
        .legacy .quicklinks-holder .grid-col.new-row {
          border-right: none; } }
    @media only screen and (min-width: 1025px) {
      .legacy .quicklinks-holder .grid-col {
        border-bottom: none;
        padding: 0 10px; } }
  .legacy .quicklinks-holder h3,
  .legacy .quicklinks-holder a {
    color: #0056a2;
    text-decoration: none; }

.legacy .component-wrap {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0 auto 40px; }
  .legacy .component-wrap.no-bottom-space {
    margin-bottom: 0;
    padding-bottom: 0; }
  .legacy .component-wrap.gray-bg {
    background: #f8f8f8;
    padding: 64px 0 32px; }
  .legacy .component-wrap .component {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
    max-width: 1224px; }
    @media only screen and (min-width: 1025px) {
      .legacy .component-wrap .component {
        padding: 0; } }

.legacy .callout-header,
.legacy .collection-header,
.legacy .collection-product-carousel-top {
  padding: 0;
  margin: 0;
  text-align: left; }
  .legacy .callout-header h3,
  .legacy .callout-header h4,
  .legacy .callout-header h5,
  .legacy .callout-header p,
  .legacy .collection-header h3,
  .legacy .collection-header h4,
  .legacy .collection-header h5,
  .legacy .collection-header p,
  .legacy .collection-product-carousel-top h3,
  .legacy .collection-product-carousel-top h4,
  .legacy .collection-product-carousel-top h5,
  .legacy .collection-product-carousel-top p {
    padding: 0 0 24px;
    margin: 0; }
  @media only screen and (min-width: 1025px) {
    .legacy .callout-header,
    .legacy .collection-header,
    .legacy .collection-product-carousel-top {
      text-align: center; } }

.legacy .slider .this-slider:not(.slick-initialized),
.legacy .header-ribbon.secondary .this-slider:not(.slick-initialized) {
  display: none; }

.legacy .slick-arrow {
  width: 50px;
  height: 50px;
  z-index: 1;
  color: var(--color-blue); }
  .legacy .slick-arrow.right-arrow {
    right: 5px; }
    @media only screen and (min-width: 1300px) {
      .legacy .slick-arrow.right-arrow {
        right: -30px; } }
  .legacy .slick-arrow.left-arrow {
    left: 5px; }
    @media only screen and (min-width: 1300px) {
      .legacy .slick-arrow.left-arrow {
        left: -30px; } }

.legacy .slick-active button {
  border-radius: 5px; }

.legacy .slick-dots {
  bottom: -25px; }
  .legacy .slick-dots li {
    border-radius: 100%;
    border: 1px solid #0056a2;
    height: 12px;
    width: 12px;
    background: #fff; }
    .legacy .slick-dots li button {
      height: 0;
      width: 0;
      min-height: 0;
      padding: 4px;
      position: relative;
      top: 2px;
      left: 2px; }
      .legacy .slick-dots li button::before {
        opacity: 0; }
    .legacy .slick-dots li.slick-active button {
      background: #e62144; }
      .legacy .slick-dots li.slick-active button::before {
        opacity: 0; }
  @media only screen and (min-width: 1025px) {
    .legacy .slick-dots {
      width: auto;
      bottom: 50px;
      left: 70px; } }

.legacy .component.slider .this-slider {
  width: 100%;
  margin: 0 auto;
  text-align: center; }
  .legacy .component.slider .this-slider .slick-arrow {
    width: 40px;
    height: 40px;
    top: 35%; }
    @media only screen and (min-width: 1300px) {
      .legacy .component.slider .this-slider .slick-arrow.right-arrow {
        right: -10px; } }
    @media only screen and (min-width: 1300px) {
      .legacy .component.slider .this-slider .slick-arrow.left-arrow {
        left: -10px; } }

.legacy .component.slider img {
  width: 100%;
  margin: 0 auto; }

.legacy .component.circlenav img {
  max-width: 128px; }

.legacy .component.circlenav a {
  text-decoration: none; }

.legacy .component.circlenav h4 {
  font-size: 1em;
  color: #1d3557;
  margin-bottom: 0; }

.legacy .component.circlenav .shadow img {
  border-radius: 50%;
  border-color: #f0eeee;
  border-style: solid;
  border-width: 2px 6px 6px 2px;
  background-color: #f0eeee; }

.legacy .component.generic .ugc-slide {
  max-width: 288px;
  padding: 0 12px;
  margin: auto; }
  .legacy .component.generic .ugc-slide .copy-wrap {
    text-align: left; }
    .legacy .component.generic .ugc-slide .copy-wrap h5 {
      margin-bottom: 1px;
      font-weight: 100;
      font-size: 16px;
      min-height: 80px; }
    .legacy .component.generic .ugc-slide .copy-wrap .expsub {
      font-weight: 600;
      font-size: .875em;
      margin: 8px auto; }
    .legacy .component.generic .ugc-slide .copy-wrap p {
      font-size: .825em;
      margin: 8px auto; }
    .legacy .component.generic .ugc-slide .copy-wrap .button {
      display: block; }

.legacy .component.generic .home-bear-builder-slide {
  width: 288px; }
  .legacy .component.generic .home-bear-builder-slide img {
    max-width: 186px; }

.legacy .component.generic .generic-slide {
  padding: 0 20px; }
  .legacy .component.generic .generic-slide .copy-wrap {
    text-align: left; }
  .legacy .component.generic .generic-slide .copy-wrap h5 {
    margin-bottom: 1px; }
  .legacy .component.generic .generic-slide .copy-wrap .expsub {
    font-weight: 600;
    font-size: .875em;
    margin: 8px auto; }
  .legacy .component.generic .generic-slide .copy-wrap p {
    font-size: .825em;
    margin: 8px auto; }

.legacy .component.double .slide-content-wrap {
  padding: 0 16px; }

.legacy .component.double .this-slider .slick-arrow {
  top: 48%; }
  .legacy .component.double .this-slider .slick-arrow.left-arrow {
    left: -12px; }
  .legacy .component.double .this-slider .slick-arrow.right-arrow {
    right: -12px; }

.legacy .component.double .slide-double {
  text-align: left; }
  @media only screen and (max-width: 768px) {
    .legacy .component.double .slide-double.container {
      font-size: .8rem;
      line-height: 1.2em; } }
  .legacy .component.double .slide-double .copy-wrap {
    background-color: #f8f8f8;
    border: 2px solid #f8f8f8; }
    .legacy .component.double .slide-double .copy-wrap h5 {
      margin: 12px auto; }
      @media only screen and (min-width: 1025px) {
        .legacy .component.double .slide-double .copy-wrap h5 {
          margin: 16px auto; } }
    @media only screen and (min-width: 1025px) {
      .legacy .component.double .slide-double .copy-wrap .slide-cta-bottom {
        position: absolute;
        bottom: 32px; } }
    .legacy .component.double .slide-double .copy-wrap .slide-cta-bottom a {
      font-weight: bold;
      text-decoration: underline; }
  .legacy .component.double .slide-double .hero-wrap {
    padding: 0;
    background-color: #f8f8f8; }
    .legacy .component.double .slide-double .hero-wrap img {
      width: 100%; }

.legacy .component.marketing-drawer-slider {
  padding: 0; }
  .legacy .component.marketing-drawer-slider .container {
    padding: 0 0 16px; }
    .legacy .component.marketing-drawer-slider .container .row .left h5 {
      padding: 0;
      margin: 0;
      font-size: 1rem;
      line-height: initial;
      color: #fff; }
      @media only screen and (min-width: 1025px) {
        .legacy .component.marketing-drawer-slider .container .row .left h5 {
          font-size: 1em;
          line-height: initial; } }
    .legacy .component.marketing-drawer-slider .container .row .right {
      text-align: right; }
      .legacy .component.marketing-drawer-slider .container .row .right a {
        font-size: 1rem;
        color: #fff; }
        @media only screen and (min-width: 1025px) {
          .legacy .component.marketing-drawer-slider .container .row .right a {
            font-size: 1em; } }
      .legacy .component.marketing-drawer-slider .container .row .right i {
        display: inline-block;
        margin-bottom: -3px; }
  @media only screen and (min-width: 1025px) {
    .legacy .component.marketing-drawer-slider .this-slider {
      padding-top: 30px; } }
  .legacy .component.marketing-drawer-slider .this-slider .slick-arrow {
    top: 145px; }
  .legacy .component.marketing-drawer-slider .this-slider .slick-slide {
    padding: 0 10px; }
  .legacy .component.marketing-drawer-slider .this-slider .slide-content-wrap {
    margin: 0 auto;
    position: relative;
    float: left;
    padding: 6px;
    width: 100%; }
    @media only screen and (min-width: 1025px) {
      .legacy .component.marketing-drawer-slider .this-slider .slide-content-wrap {
        padding: 0; } }
    .legacy .component.marketing-drawer-slider .this-slider .slide-content-wrap img {
      position: relative;
      z-index: 10;
      float: none;
      max-height: 210px;
      width: 100%; }
    .legacy .component.marketing-drawer-slider .this-slider .slide-content-wrap p,
    .legacy .component.marketing-drawer-slider .this-slider .slide-content-wrap a.link {
      float: left;
      text-align: left;
      margin: 0;
      display: inline-block; }
    .legacy .component.marketing-drawer-slider .this-slider .slide-content-wrap > p {
      margin: 12px auto 2px;
      padding: 8px;
      height: 80px;
      border: 1px solid #f2f2f2;
      overflow-y: auto;
      width: 100%;
      display: none; }
    .legacy .component.marketing-drawer-slider .this-slider .slide-content-wrap a.link {
      padding-bottom: 16px;
      font-size: .8rem; }
    .legacy .component.marketing-drawer-slider .this-slider .slide-content-wrap .btn-wrap {
      position: relative;
      height: 100%; }
      .legacy .component.marketing-drawer-slider .this-slider .slide-content-wrap .btn-wrap::after {
        content: "";
        display: table;
        clear: both; }
    .legacy .component.marketing-drawer-slider .this-slider .slide-content-wrap .disclaimer {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      background: white;
      max-height: 275px;
      height: 100%;
      overflow-x: auto;
      border: 1px solid #1d3557;
      padding: 8px;
      z-index: 100; }
      .legacy .component.marketing-drawer-slider .this-slider .slide-content-wrap .disclaimer a.disc-close {
        position: absolute;
        top: 0;
        right: 10px;
        text-decoration: none;
        -webkit-transform: rotateY(0deg) rotate(45deg);
                transform: rotateY(0deg) rotate(45deg);
        color: #0056a2; }
      .legacy .component.marketing-drawer-slider .this-slider .slide-content-wrap .disclaimer p {
        font-size: 12px; }
    @media only screen and (min-width: 425px) and (max-width: 576px) {
      .legacy .component.marketing-drawer-slider .this-slider .slide-content-wrap {
        width: 100%; }
        .legacy .component.marketing-drawer-slider .this-slider .slide-content-wrap img {
          float: none;
          max-height: 210px;
          width: auto; } }

.legacy .component.product-slider .product-tile .tile-badges {
  position: absolute;
  top: 0;
  left: 0; }
  .legacy .component.product-slider .product-tile .tile-badges img {
    max-height: 50px;
    min-height: 0;
    max-width: 85%;
    float: left;
    margin-right: 5px; }
    @media only screen and (min-width: 768px) {
      .legacy .component.product-slider .product-tile .tile-badges img {
        max-height: none; } }

.legacy .component-wrap.ribbon {
  background: url("../images/ribbon-slider.png") center no-repeat;
  background-size: 100%; }

.legacy .main-carousel {
  margin: 0;
  padding-top: 20px; }
  .legacy .main-carousel .slick-slide {
    padding: 10px 0; }
  .legacy .main-carousel .slick-arrow {
    width: 60px;
    height: 60px; }
    .legacy .main-carousel .slick-arrow.right-arrow {
      right: 10px; }
    .legacy .main-carousel .slick-arrow.left-arrow {
      left: 10px; }
  .legacy .main-carousel a {
    max-width: 600px;
    display: block;
    background: white;
    padding: 0 0 10px 0;
    margin: 0 5px;
    text-align: center;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15); }
    .legacy .main-carousel a img {
      width: 100%; }
    .legacy .main-carousel a .button {
      position: relative;
      margin: 10px auto 0 auto;
      width: 100%; }
    @media only screen and (min-width: 768px) {
      .legacy .main-carousel a {
        padding: 0 0 10px 0;
        margin: 0 10px; } }
  .legacy .main-carousel .slick-dots {
    position: relative;
    bottom: 10px;
    margin: 0 auto;
    left: auto; }

.legacy .component.highlight {
  position: relative; }
  .legacy .component.highlight .row .hero-wrap {
    padding: 0; }
    .legacy .component.highlight .row .hero-wrap img {
      display: block;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      margin: 0 auto;
      border-radius: 4px 4px 0 0; }
      @media only screen and (min-width: 992px) {
        .legacy .component.highlight .row .hero-wrap img {
          border-radius: 4px 0 0 4px; } }
  .legacy .component.highlight .row .copy-wrap {
    text-align: left;
    padding: 16px;
    border-radius: 0 0 4px 4px; }
    @media only screen and (min-width: 992px) {
      .legacy .component.highlight .row .copy-wrap {
        padding: 24px;
        border-radius: 0 4px 4px 0; } }
    .legacy .component.highlight .row .copy-wrap h3,
    .legacy .component.highlight .row .copy-wrap h4,
    .legacy .component.highlight .row .copy-wrap h5,
    .legacy .component.highlight .row .copy-wrap p,
    .legacy .component.highlight .row .copy-wrap a.link {
      color: white;
      padding: 8px 0;
      margin: 0; }
      @media only screen and (min-width: 992px) {
        .legacy .component.highlight .row .copy-wrap h3,
        .legacy .component.highlight .row .copy-wrap h4,
        .legacy .component.highlight .row .copy-wrap h5,
        .legacy .component.highlight .row .copy-wrap p,
        .legacy .component.highlight .row .copy-wrap a.link {
          padding: 16px 0; } }
    .legacy .component.highlight .row .copy-wrap h3 {
      line-height: 2.5rem; }
    .legacy .component.highlight .row .copy-wrap.invert h3,
    .legacy .component.highlight .row .copy-wrap.invert h4,
    .legacy .component.highlight .row .copy-wrap.invert h5,
    .legacy .component.highlight .row .copy-wrap.invert p,
    .legacy .component.highlight .row .copy-wrap.invert a.link {
      color: #1d3557; }

.legacy .component.hero.reverse .row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse; }
  @media only screen and (min-width: 992px) {
    .legacy .component.hero.reverse .row {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; } }
  @media only screen and (min-width: 992px) {
    .legacy .component.hero.reverse .row .hero-wrap img {
      border-radius: 0 4px 4px 0; } }
  @media only screen and (min-width: 992px) {
    .legacy .component.hero.reverse .row .copy-wrap {
      border-radius: 4px 0 0 4px; } }

.legacy .component.highlight.mini {
  position: relative; }
  .legacy .component.highlight.mini .row .hero-wrap {
    padding: 48px; }
    @media only screen and (min-width: 1025px) {
      .legacy .component.highlight.mini .row .hero-wrap {
        padding: 0; } }
    .legacy .component.highlight.mini .row .hero-wrap img {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
  .legacy .component.highlight.mini .row .copy-wrap {
    padding: 40px; }
    @media only screen and (min-width: 1025px) {
      .legacy .component.highlight.mini .row .copy-wrap {
        padding: 0 40px; } }
    .legacy .component.highlight.mini .row .copy-wrap .button {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
  .legacy .component.highlight.mini.cream.solid .row .hero-wrap {
    background-color: #f8a022; }
  .legacy .component.highlight.mini.cream .row .hero-wrap {
    border: 2px solid #f8a022;
    background-color: #fff; }
  .legacy .component.highlight.mini.cream .copy-wrap {
    background-color: #fff5e4; }
    .legacy .component.highlight.mini.cream .copy-wrap h4,
    .legacy .component.highlight.mini.cream .copy-wrap p {
      color: #1d3557; }
  .legacy .component.highlight.mini.blue .row .hero-wrap {
    border: 2px solid #f2fbfe;
    background-color: white; }
  .legacy .component.highlight.mini.blue .copy-wrap {
    background-color: #f2fbfe; }
    .legacy .component.highlight.mini.blue .copy-wrap h4,
    .legacy .component.highlight.mini.blue .copy-wrap p {
      color: #1d3557; }

.legacy .component.double .row div > .container {
  margin-bottom: 24px; }
  @media only screen and (min-width: 1025px) {
    .legacy .component.double .row div > .container {
      margin-bottom: 0; } }

.legacy .component.text-expander {
  border: 1px solid #0b58a0;
  border-radius: 4px; }
  .legacy .component.text-expander i {
    float: right; }

/* recommender carousel */
.legacy .capture-product-id {
  left: -999em;
  position: absolute; }

.legacy .reco-products .tab-label {
  text-align: center;
  font-size: 1.5rem;
  margin: 20px auto; }
  @media only screen and (min-width: 768px) {
    .legacy .reco-products .tab-label {
      font-size: 2.125rem; } }

.legacy .collection-product-carousel,
.legacy .recommender-carousel,
.legacy .einstein-recommendations {
  background: #f8f8f8; }
  .legacy .collection-product-carousel .collection-product-carousel-container,
  .legacy .recommender-carousel .collection-product-carousel-container,
  .legacy .einstein-recommendations .collection-product-carousel-container {
    width: 100%;
    max-width: 1224px;
    margin: 0 auto; }
    .legacy .collection-product-carousel .collection-product-carousel-container::after,
    .legacy .recommender-carousel .collection-product-carousel-container::after,
    .legacy .einstein-recommendations .collection-product-carousel-container::after {
      content: "";
      display: table;
      clear: both; }
  .legacy .collection-product-carousel .product,
  .legacy .recommender-carousel .product,
  .legacy .einstein-recommendations .product {
    display: none; }
  .legacy .collection-product-carousel .slick-initialized,
  .legacy .collection-product-carousel .slick-track,
  .legacy .recommender-carousel .slick-initialized,
  .legacy .recommender-carousel .slick-track,
  .legacy .einstein-recommendations .slick-initialized,
  .legacy .einstein-recommendations .slick-track {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
    .legacy .collection-product-carousel .slick-initialized .product,
    .legacy .collection-product-carousel .slick-track .product,
    .legacy .recommender-carousel .slick-initialized .product,
    .legacy .recommender-carousel .slick-track .product,
    .legacy .einstein-recommendations .slick-initialized .product,
    .legacy .einstein-recommendations .slick-track .product {
      display: block; }
    .legacy .collection-product-carousel .slick-initialized .slick-slide,
    .legacy .collection-product-carousel .slick-track .slick-slide,
    .legacy .recommender-carousel .slick-initialized .slick-slide,
    .legacy .recommender-carousel .slick-track .slick-slide,
    .legacy .einstein-recommendations .slick-initialized .slick-slide,
    .legacy .einstein-recommendations .slick-track .slick-slide {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: inherit !important;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      height: 100%;
      margin-bottom: 32px; }
      .legacy .collection-product-carousel .slick-initialized .slick-slide .product-tile,
      .legacy .collection-product-carousel .slick-track .slick-slide .product-tile,
      .legacy .recommender-carousel .slick-initialized .slick-slide .product-tile,
      .legacy .recommender-carousel .slick-track .slick-slide .product-tile,
      .legacy .einstein-recommendations .slick-initialized .slick-slide .product-tile,
      .legacy .einstein-recommendations .slick-track .slick-slide .product-tile {
        margin-bottom: 40px;
        max-width: 100%; }
      .legacy .collection-product-carousel .slick-initialized .slick-slide > div,
      .legacy .collection-product-carousel .slick-initialized .slick-slide .slide,
      .legacy .collection-product-carousel .slick-track .slick-slide > div,
      .legacy .collection-product-carousel .slick-track .slick-slide .slide,
      .legacy .recommender-carousel .slick-initialized .slick-slide > div,
      .legacy .recommender-carousel .slick-initialized .slick-slide .slide,
      .legacy .recommender-carousel .slick-track .slick-slide > div,
      .legacy .recommender-carousel .slick-track .slick-slide .slide,
      .legacy .einstein-recommendations .slick-initialized .slick-slide > div,
      .legacy .einstein-recommendations .slick-initialized .slick-slide .slide,
      .legacy .einstein-recommendations .slick-track .slick-slide > div,
      .legacy .einstein-recommendations .slick-track .slick-slide .slide {
        height: 100%; }
        .legacy .collection-product-carousel .slick-initialized .slick-slide > div .product-tile,
        .legacy .collection-product-carousel .slick-initialized .slick-slide .slide .product-tile,
        .legacy .collection-product-carousel .slick-track .slick-slide > div .product-tile,
        .legacy .collection-product-carousel .slick-track .slick-slide .slide .product-tile,
        .legacy .recommender-carousel .slick-initialized .slick-slide > div .product-tile,
        .legacy .recommender-carousel .slick-initialized .slick-slide .slide .product-tile,
        .legacy .recommender-carousel .slick-track .slick-slide > div .product-tile,
        .legacy .recommender-carousel .slick-track .slick-slide .slide .product-tile,
        .legacy .einstein-recommendations .slick-initialized .slick-slide > div .product-tile,
        .legacy .einstein-recommendations .slick-initialized .slick-slide .slide .product-tile,
        .legacy .einstein-recommendations .slick-track .slick-slide > div .product-tile,
        .legacy .einstein-recommendations .slick-track .slick-slide .slide .product-tile {
          height: 100%; }
      .legacy .collection-product-carousel .slick-initialized .slick-slide .collection-product-slide,
      .legacy .collection-product-carousel .slick-track .slick-slide .collection-product-slide,
      .legacy .recommender-carousel .slick-initialized .slick-slide .collection-product-slide,
      .legacy .recommender-carousel .slick-track .slick-slide .collection-product-slide,
      .legacy .einstein-recommendations .slick-initialized .slick-slide .collection-product-slide,
      .legacy .einstein-recommendations .slick-track .slick-slide .collection-product-slide {
        height: 100%; }
      @media only screen and (min-width: 768px) {
        .legacy .collection-product-carousel .slick-initialized .slick-slide .product-tile,
        .legacy .collection-product-carousel .slick-track .slick-slide .product-tile,
        .legacy .recommender-carousel .slick-initialized .slick-slide .product-tile,
        .legacy .recommender-carousel .slick-track .slick-slide .product-tile,
        .legacy .einstein-recommendations .slick-initialized .slick-slide .product-tile,
        .legacy .einstein-recommendations .slick-track .slick-slide .product-tile {
          margin-bottom: 40px;
          max-width: 230px; } }
  .legacy .collection-product-carousel .collection-product-carousel-top p,
  .legacy .recommender-carousel .collection-product-carousel-top p,
  .legacy .einstein-recommendations .collection-product-carousel-top p {
    padding: 0 0.625rem; }
  .legacy .collection-product-carousel.two-row,
  .legacy .recommender-carousel.two-row,
  .legacy .einstein-recommendations.two-row {
    /* mobile view only */ }
    @media only screen and (max-width: 992px) {
      .legacy .collection-product-carousel.two-row .slick-initialized,
      .legacy .collection-product-carousel.two-row .slick-track,
      .legacy .recommender-carousel.two-row .slick-initialized,
      .legacy .recommender-carousel.two-row .slick-track,
      .legacy .einstein-recommendations.two-row .slick-initialized,
      .legacy .einstein-recommendations.two-row .slick-track {
        display: block; }
        .legacy .collection-product-carousel.two-row .slick-initialized .slick-slide,
        .legacy .collection-product-carousel.two-row .slick-track .slick-slide,
        .legacy .recommender-carousel.two-row .slick-initialized .slick-slide,
        .legacy .recommender-carousel.two-row .slick-track .slick-slide,
        .legacy .einstein-recommendations.two-row .slick-initialized .slick-slide,
        .legacy .einstein-recommendations.two-row .slick-track .slick-slide {
          display: block;
          height: auto !important;
          margin-bottom: 0; }
          .legacy .collection-product-carousel.two-row .slick-initialized .slick-slide > div,
          .legacy .collection-product-carousel.two-row .slick-track .slick-slide > div,
          .legacy .recommender-carousel.two-row .slick-initialized .slick-slide > div,
          .legacy .recommender-carousel.two-row .slick-track .slick-slide > div,
          .legacy .einstein-recommendations.two-row .slick-initialized .slick-slide > div,
          .legacy .einstein-recommendations.two-row .slick-track .slick-slide > div {
            height: auto !important;
            margin-bottom: 24px;
            position: relative; }
        .legacy .collection-product-carousel.two-row .slick-initialized .product-tile,
        .legacy .collection-product-carousel.two-row .slick-track .product-tile,
        .legacy .recommender-carousel.two-row .slick-initialized .product-tile,
        .legacy .recommender-carousel.two-row .slick-track .product-tile,
        .legacy .einstein-recommendations.two-row .slick-initialized .product-tile,
        .legacy .einstein-recommendations.two-row .slick-track .product-tile {
          margin: 0 auto 16px;
          min-height: 390px; } }

.legacy.pt_categorylanding {
  width: 100%;
  max-width: 100%; }
  .legacy.pt_categorylanding .primary-content {
    padding-bottom: 0; }
  .legacy.pt_categorylanding #secondary {
    display: none; }
    @media only screen and (min-width: 768px) {
      .legacy.pt_categorylanding #secondary {
        display: block; } }

.bottom-banner-container {
  overflow: hidden; }
  .bottom-banner-container .bottom-banner-cell {
    float: left;
    overflow: hidden;
    text-align: center; }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      .bottom-banner-container .bottom-banner-cell img {
        max-width: 100%; } }
  .bottom-banner-container .banner-cell-1 {
    width: 33%; }
  .bottom-banner-container .banner-cell-2 {
    width: 34%; }
  .bottom-banner-container .banner-cell-3 {
    width: 33%; }

.legacy.pt_collection {
  width: 100%;
  max-width: 100%; }
  .legacy.pt_collection h1 {
    text-align: center;
    font-size: 2em;
    color: #1d3557;
    padding-bottom: 16px; }
  .legacy.pt_collection .section-title {
    text-align: center;
    font-size: 2.125rem;
    padding: 40px 0 20px 0; }
  .legacy.pt_collection img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
    vertical-align: top; }
  @media only screen and (min-width: 768px) {
    .legacy.pt_collection .collection-large-up-padding-bottom-20 {
      padding-bottom: 1.25rem; } }
  .legacy.pt_collection .collection-padding-top-bottom-25 {
    padding: 1.5625rem 0; }
  .legacy.pt_collection .collection-padding-top-80 {
    padding-top: 1.25rem; }
    @media only screen and (min-width: 768px) {
      .legacy.pt_collection .collection-padding-top-80 {
        padding-top: 5rem; } }
  .legacy.pt_collection .collection-padding-bottom-80 {
    padding-bottom: 1.25rem; }
    @media only screen and (min-width: 768px) {
      .legacy.pt_collection .collection-padding-bottom-80 {
        padding-bottom: 5rem; } }
  .legacy.pt_collection .collection-padding-top-40 {
    padding-top: 1.25rem; }
    @media only screen and (min-width: 768px) {
      .legacy.pt_collection .collection-padding-top-40 {
        padding-top: 2.5rem; } }
  .legacy.pt_collection .collection-padding-bottom-40 {
    padding-bottom: 1.25rem; }
    @media only screen and (min-width: 768px) {
      .legacy.pt_collection .collection-padding-bottom-40 {
        padding-bottom: 2.5rem; } }
  .legacy.pt_collection .collection-padding-top-60 {
    padding-top: 1.25rem; }
    @media only screen and (min-width: 768px) {
      .legacy.pt_collection .collection-padding-top-60 {
        padding-top: 3.75rem; } }
  .legacy.pt_collection .collection-padding-bottom-60 {
    padding-bottom: 1.25rem; }
    @media only screen and (min-width: 768px) {
      .legacy.pt_collection .collection-padding-bottom-60 {
        padding-bottom: 3.75rem; } }
  .legacy.pt_collection .collection-padding-bottom-20 {
    padding-bottom: 1.25rem; }
  .legacy.pt_collection .collection-padding-bottom-30 {
    padding-bottom: 1.875rem; }
  .legacy.pt_collection .collection-verticaly-centered-large-up {
    position: static;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .legacy.pt_collection .collection-verticaly-centered-large-up {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: auto; } }
  .legacy.pt_collection .collection-relative {
    position: relative; }
  .legacy.pt_collection .container-max-width-1180 {
    width: 100%;
    max-width: 1224px;
    margin: 0 auto; }
    .legacy.pt_collection .container-max-width-1180::after {
      content: "";
      display: table;
      clear: both; }
  .legacy.pt_collection .center {
    text-align: center; }
    .legacy.pt_collection .center p {
      padding: 0 0.625rem; }
  .legacy.pt_collection .box {
    width: 100%;
    height: 100%;
    margin-bottom: 30px; }
    .legacy.pt_collection .box::after {
      content: "";
      display: table;
      clear: both; }
    .legacy.pt_collection .box * {
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
    .legacy.pt_collection .box.border.bdr-light {
      border: 1px solid #88b2d5; }
    .legacy.pt_collection .box.filled {
      /* paws */
      /* stars and hearts */ }
      .legacy.pt_collection .box.filled.pattern-1 {
        background-color: #fff;
        background-image: url("../images/paw-pattern.png");
        background-repeat: repeat; }
      .legacy.pt_collection .box.filled.pattern-2 {
        background-color: #0056a2; }
      .legacy.pt_collection .box.filled.light {
        background-color: #f2fbfe;
        color: #0056a2; }
  .legacy.pt_collection .content-box {
    position: relative;
    width: 100%;
    height: 370px;
    padding: 0;
    margin: 0; }
    .legacy.pt_collection .content-box .text-box {
      height: auto;
      padding: 10px;
      color: white; }
      .legacy.pt_collection .content-box .text-box h4 {
        font-size: 2em;
        padding-bottom: 10px; }
      .legacy.pt_collection .content-box .text-box p {
        font-size: 1.2em;
        padding-bottom: 10px; }
    .legacy.pt_collection .content-box .stack {
      width: 100%;
      height: 50%;
      padding: 0;
      margin: 0; }
      .legacy.pt_collection .content-box .stack.full {
        height: 100%; }
    .legacy.pt_collection .content-box .video {
      padding: 35px 20px 0;
      text-align: center; }
      .legacy.pt_collection .content-box .video .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
        max-width: 560px;
        margin: 0 auto; }
      .legacy.pt_collection .content-box .video .video-container iframe,
      .legacy.pt_collection .content-box .video .video-container object,
      .legacy.pt_collection .content-box .video .video-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 300px;
        max-width: 560px; }
    .legacy.pt_collection .content-box .left,
    .legacy.pt_collection .content-box .right {
      width: 100%;
      height: 100%;
      float: left; }
      .legacy.pt_collection .content-box .left img,
      .legacy.pt_collection .content-box .right img {
        width: 100%;
        min-width: 100%;
        min-height: 100%; }
    .legacy.pt_collection .content-box.half .left {
      width: 100%; }
      @media only screen and (min-width: 768px) {
        .legacy.pt_collection .content-box.half .left {
          width: 50%; } }
    .legacy.pt_collection .content-box.half .right {
      width: 100%; }
      @media only screen and (min-width: 768px) {
        .legacy.pt_collection .content-box.half .right {
          width: 50%; } }
    .legacy.pt_collection .content-box.left-third .left {
      width: 100%; }
      @media only screen and (min-width: 768px) {
        .legacy.pt_collection .content-box.left-third .left {
          width: 33.33%; } }
    .legacy.pt_collection .content-box.left-third .right {
      width: 100%; }
      @media only screen and (min-width: 768px) {
        .legacy.pt_collection .content-box.left-third .right {
          width: 66.66%; } }
    .legacy.pt_collection .content-box.right-third .left {
      width: 100%; }
      @media only screen and (min-width: 768px) {
        .legacy.pt_collection .content-box.right-third .left {
          width: 66.66%; } }
    .legacy.pt_collection .content-box.right-third .right {
      width: 100%; }
      @media only screen and (min-width: 768px) {
        .legacy.pt_collection .content-box.right-third .right {
          width: 33.33%; } }

.collection-landing-big-slider {
  margin: 0; }
  .collection-landing-big-slider li {
    position: relative; }
  .collection-landing-big-slider .slick-slide {
    padding: 10px 0; }
  .collection-landing-big-slider .slick-arrow {
    width: 60px;
    height: 60px; }
    .collection-landing-big-slider .slick-arrow.right-arrow {
      right: 10px; }
    .collection-landing-big-slider .slick-arrow.left-arrow {
      left: 10px;
      right: 0; }
  .collection-landing-big-slider a {
    max-width: 600px;
    display: block;
    background: white;
    padding: 0 0 10px 0;
    margin: 0 5px;
    text-align: center;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15); }
    .collection-landing-big-slider a img {
      width: 100%; }
    .collection-landing-big-slider a .button {
      position: relative;
      margin: 10px auto 0 auto;
      width: 100%; }
    @media only screen and (min-width: 768px) {
      .collection-landing-big-slider a {
        padding: 0 0 10px 0;
        margin: 0 10px; } }
  .collection-landing-big-slider .slick-dots {
    position: relative;
    bottom: 10px;
    margin: 0 auto;
    left: auto; }

.collection-landing-big-slider-container-single {
  margin: 0; }
  .collection-landing-big-slider-container-single li {
    position: relative; }
  .collection-landing-big-slider-container-single .slick-arrow {
    width: 60px;
    height: 60px; }
    .collection-landing-big-slider-container-single .slick-arrow.right-arrow {
      right: 10px; }
    .collection-landing-big-slider-container-single .slick-arrow.left-arrow {
      left: 10px;
      right: 0; }
  .collection-landing-big-slider-container-single a {
    width: 100%;
    display: block;
    text-align: center; }
    .collection-landing-big-slider-container-single a img {
      width: 100%; }
  .collection-landing-big-slider-container-single .slick-dots {
    position: relative;
    bottom: 10px;
    margin: 0 auto;
    left: auto; }

.collection-landing-four-sliders-container {
  width: 100%;
  max-width: 1224px;
  margin: 0 auto;
  text-align: center;
  padding: 1.25rem 0; }
  .collection-landing-four-sliders-container::after {
    content: "";
    display: table;
    clear: both; }
  @media only screen and (min-width: 768px) {
    .collection-landing-four-sliders-container {
      padding-bottom: 2.5rem; } }
  .collection-landing-four-sliders-container .collection-header {
    padding: 0 0.625rem 0.625rem 0.625rem; }
    @media only screen and (min-width: 768px) {
      .collection-landing-four-sliders-container .collection-header {
        padding-bottom: 1.25rem; } }
  .collection-landing-four-sliders-container .collection-landing-four-sliders {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 2.8125rem; }
    @media only screen and (min-width: 768px) {
      .collection-landing-four-sliders-container .collection-landing-four-sliders li {
        width: 25%;
        padding: 0 0.9375rem;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        display: inline-block; } }

.collection-landing-four-sliders-peek-container {
  margin: 0 auto;
  text-align: center; }
  @media only screen and (min-width: 768px) {
    .collection-landing-four-sliders-peek-container {
      padding-bottom: 2.5rem; } }
  .collection-landing-four-sliders-peek-container .collection-header {
    padding: 0 0.625rem 0.625rem 0.625rem; }
    @media only screen and (min-width: 768px) {
      .collection-landing-four-sliders-peek-container .collection-header {
        padding-bottom: 1.25rem; } }
  .collection-landing-four-sliders-peek-container .collection-landing-four-peek-sliders {
    display: table;
    table-layout: fixed;
    width: 100%;
    margin: 0 auto; }
  .collection-landing-four-sliders-peek-container .slick-list {
    overflow: visible; }

.collection-landing-one-slide-container {
  text-align: center; }
  .collection-landing-one-slide-container a {
    max-width: 600px; }
    .collection-landing-one-slide-container a img {
      width: 100%; }

.collection-detail-full-width {
  padding: 1.25rem;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

@media only screen and (min-width: 768px) {
  .collection-detail-two-block-container {
    display: table; } }

.collection-detail-image-50-left {
  text-align: center;
  padding: 0 0.9375rem; }
  @media only screen and (min-width: 768px) {
    .collection-detail-image-50-left {
      display: table-cell;
      vertical-align: middle;
      width: 50%;
      padding: 0 9%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; } }

.collection-detail-image-65-left {
  text-align: center;
  padding: 0 0.9375rem; }
  @media only screen and (min-width: 768px) {
    .collection-detail-image-65-left {
      display: table-cell;
      vertical-align: middle;
      width: 65%;
      padding: 0 9%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; } }

.collection-detail-image-50-right {
  text-align: center;
  padding: 0 0.9375rem; }
  @media only screen and (min-width: 768px) {
    .collection-detail-image-50-right {
      display: table-cell;
      vertical-align: middle;
      width: 50%;
      padding: 0 9%; } }

.collection-detail-text-50-right {
  text-align: left;
  padding: 0 0.9375rem; }
  @media only screen and (min-width: 768px) {
    .collection-detail-text-50-right {
      display: table-cell;
      vertical-align: middle;
      width: 50%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      padding: 0 9.3% 0 0; } }

.collection-detail-text-50-left {
  text-align: left;
  padding: 0 0.9375rem; }
  @media only screen and (min-width: 768px) {
    .collection-detail-text-50-left {
      display: table-cell;
      vertical-align: middle;
      width: 50%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      padding: 0 0 0 9.3%; } }

.collection-detail-text-35-right {
  padding: 2.5rem 0.9375rem 0 0.9375rem;
  text-align: center; }
  @media only screen and (min-width: 768px) {
    .collection-detail-text-35-right {
      text-align: left;
      display: table-cell;
      vertical-align: top;
      width: 35%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      padding: 0 9.3% 0 0; } }
  @media only screen and (min-width: 768px) {
    .collection-detail-text-35-right img {
      margin: 0 0 0.625rem 0; } }

.collection-landing-grid {
  padding: 1.25rem 0; }
  .collection-landing-grid .collection-header {
    margin: 0 auto;
    text-align: center;
    padding-bottom: 0.625rem; }
    @media only screen and (min-width: 768px) {
      .collection-landing-grid .collection-header {
        padding-bottom: 1.25rem; } }
  .collection-landing-grid ul {
    width: 100%;
    margin: 0 auto; }
    @media only screen and (min-width: 768px) {
      .collection-landing-grid ul {
        max-width: 1264px; } }
  .collection-landing-grid li {
    width: 50%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    float: left;
    padding: 0 0.46875rem 0.9375rem 0.46875rem; }
    @media only screen and (min-width: 768px) {
      .collection-landing-grid li {
        width: 25%;
        padding: 0 0.625rem 1.25rem 0.625rem; } }
    @media only screen and (min-width: 1025px) {
      .collection-landing-grid li {
        padding: 0 1.25rem 2.5rem 1.25rem; } }

.legacy .peacock-blue-button {
  background: #0056a2;
  border-color: #0056a2; }

.legacy .eminence-text-color {
  color: #622874; }

.legacy .red-violet-button {
  background: #ce147c;
  border-color: #ce147c; }

.legacy .fuchsia-blue-text-color {
  color: #8249C3; }

.legacy .fuchsia-blue-button {
  background: #8249C3;
  border-color: #8249C3; }

.legacy .disco-text-color {
  color: #73165D; }

.legacy .disco-button {
  background: #73165D;
  border-color: #73165D; }

.legacy .affair-text-color {
  color: #68478D; }

.legacy .affair-button {
  background: #68478D;
  border-color: #68478D; }

.legacy .affair-background {
  background: #68478D; }

.legacy .white-text-color {
  color: #fff; }

.legacy .white-button {
  background: #fff;
  border-color: #fff;
  color: #68478D; }

.legacy .fuchsia-blue-text-color {
  color: #8249C3; }

.legacy .fuchsia-blue-button {
  background: #8249C3;
  border-color: #8249C3; }

.legacy .black-text-color {
  color: #000; }

.legacy .thunderbird-button {
  background: #CD3023;
  border-color: #CD3023; }

.legacy .collection-landing-46-width-text-container h1 {
  font-size: 18px;
  padding-bottom: 18px; }

.legacy .my-little-pony-text {
  padding-bottom: 30px;
  max-width: 288px;
  margin: 0 auto;
  text-align: center; }

.legacy .collection-landing-46-width-text-container a {
  font-size: 12px; }

.legacy .my-little-pony-text picture,
.legacy .collection-trolls-text picture,
.legacy .collection-dragons-text picture {
  position: absolute;
  top: 40px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.legacy .my-little-pony-text picture img {
  width: 12vw;
  max-width: 199px; }

.legacy .my-little-pony-container {
  position: relative; }

.legacy .collection-easter-gifs-text {
  right: 0;
  margin: 0 auto;
  padding-bottom: 30px;
  max-width: 275px; }

.legacy .collection-trolls-text {
  left: 0;
  margin: 0 auto;
  max-width: 280px;
  padding-bottom: 30px; }

.legacy .collection-dragons-text {
  right: 0;
  margin: 0 auto;
  max-width: 280px;
  padding-bottom: 30px; }

.legacy .collection-landing-big-slider-container .button {
  margin-top: 10px; }

.legacy .collection-landing-big-slider-container p {
  display: none; }

@media only screen and (min-width: 768px) {
  .legacy .my-little-pony-container {
    position: relative; }
  .legacy .my-little-pony-text {
    position: absolute;
    top: 15%;
    left: 0;
    right: 0;
    max-width: 345px;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .legacy .my-little-pony-text p,
  .legacy .collection-easter-gifs-text p,
  .legacy .collection-trolls-text p,
  .legacy .collection-dragons-text p,
  .legacy .collection-landing-big-slider-container p {
    display: block;
    font-weight: 600; }
  .legacy .collection-landing-46-width-text-container h1 {
    font-size: 30px;
    padding-bottom: 30px; }
  .legacy .collection-easter-gifs-text {
    max-width: 325px;
    left: 54%; }
  .legacy .collection-trolls-text {
    max-width: 370px;
    left: 12%; }
  .legacy .collection-dragons-text {
    right: 15%;
    max-width: 380px; }
  .legacy .my-little-pony-text picture,
  .legacy .collection-trolls-text picture,
  .legacy .collection-dragons-text picture {
    position: static; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .legacy .collection-landing-big-slider-text img {
    max-width: 60%; }
  .legacy .collection-landing-big-slider-text p,
  .legacy .my-little-pony-text p,
  .legacy .collection-easter-gifs-text p,
  .legacy .collection-trolls-text p,
  .legacy .collection-dragons-text p {
    display: none; }
  .legacy .collection-landing-big-slider .collection-landing-big-slider-text {
    right: 0; }
  .legacy .my-little-pony-text {
    top: 55px;
    padding-bottom: 0; }
  .legacy .my-little-pony-text .button,
  .legacy .collection-trolls-text .button,
  .legacy .collection-dragons-text .button {
    margin-top: 10px; }
  .legacy .collection-easter-gifs-text {
    right: 50px; }
  .legacy .collection-trolls-text {
    left: 50px; }
  .legacy .collection-dragons-text {
    right: 50px; } }

.legacy img.full-img-bleed-width {
  max-width: 100vw;
  width: 100%; }

.legacy .box,
.pt_brand .box {
  width: 100%;
  height: 100%; }
  .legacy .box::after,
  .pt_brand .box::after {
    content: "";
    display: table;
    clear: both; }
  .legacy .box.rounded,
  .pt_brand .box.rounded {
    border-radius: 5px; }
  .legacy .box.border.bdr-light,
  .pt_brand .box.border.bdr-light {
    border: 1px solid #88b2d5; }
  .legacy .box.filled,
  .pt_brand .box.filled {
    /* paws */
    /* stars and hearts */ }
    .legacy .box.filled.pattern-1,
    .pt_brand .box.filled.pattern-1 {
      background-color: #fff;
      background-image: url("../images/paw-pattern.png");
      background-repeat: repeat; }
    .legacy .box.filled.pattern-2,
    .pt_brand .box.filled.pattern-2 {
      background-color: #0056a2; }
    .legacy .box.filled.light,
    .pt_brand .box.filled.light {
      background-color: #f2fbfe;
      color: #0056a2; }

.legacy .content-box,
.pt_brand .content-box {
  position: relative;
  width: 100%;
  float: left;
  height: 100%; }
  .legacy .content-box h1,
  .pt_brand .content-box h1 {
    font-size: 1.5em; }
  .legacy .content-box .text-wrapper,
  .pt_brand .content-box .text-wrapper {
    height: auto;
    padding: 10px; }
    .legacy .content-box .text-wrapper h4,
    .pt_brand .content-box .text-wrapper h4 {
      font-size: 2em;
      padding-bottom: 20px; }
  .legacy .content-box.horizontal,
  .pt_brand .content-box.horizontal {
    height: auto; }
    .legacy .content-box.horizontal .image-wrapper,
    .pt_brand .content-box.horizontal .image-wrapper {
      width: 100%;
      height: 100%;
      float: left;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      overflow: hidden; }
      .legacy .content-box.horizontal .image-wrapper img,
      .pt_brand .content-box.horizontal .image-wrapper img {
        width: 100%;
        -ms-flex-negative: 0;
            flex-shrink: 0;
        min-width: 100%;
        min-height: 100%; }
    .legacy .content-box.horizontal .text-wrapper,
    .pt_brand .content-box.horizontal .text-wrapper {
      width: 100%;
      float: left; }
      .legacy .content-box.horizontal .text-wrapper.thin,
      .pt_brand .content-box.horizontal .text-wrapper.thin {
        margin-top: 7%; }
    .legacy .content-box.horizontal.img-half .image-wrapper,
    .pt_brand .content-box.horizontal.img-half .image-wrapper {
      width: 100%; }
      @media only screen and (min-width: 480px) {
        .legacy .content-box.horizontal.img-half .image-wrapper,
        .pt_brand .content-box.horizontal.img-half .image-wrapper {
          width: 50%; } }
    .legacy .content-box.horizontal.img-half .text-wrapper,
    .pt_brand .content-box.horizontal.img-half .text-wrapper {
      width: 100%; }
      @media only screen and (min-width: 480px) {
        .legacy .content-box.horizontal.img-half .text-wrapper,
        .pt_brand .content-box.horizontal.img-half .text-wrapper {
          width: 50%; } }
    .legacy .content-box.horizontal.img-third .image-wrapper,
    .pt_brand .content-box.horizontal.img-third .image-wrapper {
      width: 100%; }
      @media only screen and (min-width: 480px) {
        .legacy .content-box.horizontal.img-third .image-wrapper,
        .pt_brand .content-box.horizontal.img-third .image-wrapper {
          width: 33.33%; } }
    .legacy .content-box.horizontal.img-third .text-wrapper,
    .pt_brand .content-box.horizontal.img-third .text-wrapper {
      width: 100%; }
      @media only screen and (min-width: 480px) {
        .legacy .content-box.horizontal.img-third .text-wrapper,
        .pt_brand .content-box.horizontal.img-third .text-wrapper {
          width: 66.66%; } }
    .legacy .content-box.horizontal.img-quarter .image-wrapper,
    .pt_brand .content-box.horizontal.img-quarter .image-wrapper {
      width: 100%; }
      @media only screen and (min-width: 480px) {
        .legacy .content-box.horizontal.img-quarter .image-wrapper,
        .pt_brand .content-box.horizontal.img-quarter .image-wrapper {
          width: 25%; } }
    .legacy .content-box.horizontal.img-quarter .text-wrapper,
    .pt_brand .content-box.horizontal.img-quarter .text-wrapper {
      width: 100%; }
      @media only screen and (min-width: 480px) {
        .legacy .content-box.horizontal.img-quarter .text-wrapper,
        .pt_brand .content-box.horizontal.img-quarter .text-wrapper {
          width: 75%; } }
  .legacy .content-box.vertical,
  .pt_brand .content-box.vertical {
    height: 100%; }
    .legacy .content-box.vertical .image-wrapper,
    .pt_brand .content-box.vertical .image-wrapper {
      width: 100%; }
      .legacy .content-box.vertical .image-wrapper img,
      .pt_brand .content-box.vertical .image-wrapper img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover; }
      .legacy .content-box.vertical .image-wrapper.small,
      .pt_brand .content-box.vertical .image-wrapper.small {
        max-width: 200px;
        margin: 0 auto; }
    .legacy .content-box.vertical .text-wrapper,
    .pt_brand .content-box.vertical .text-wrapper {
      width: 100%;
      padding-bottom: 60px; }
      .legacy .content-box.vertical .text-wrapper .button-wrap,
      .pt_brand .content-box.vertical .text-wrapper .button-wrap {
        position: absolute;
        bottom: 10px;
        left: 50%; }
        .legacy .content-box.vertical .text-wrapper .button-wrap .button,
        .pt_brand .content-box.vertical .text-wrapper .button-wrap .button {
          position: relative;
          left: -50%; }
  .legacy .content-box.img-box,
  .pt_brand .content-box.img-box {
    height: auto; }
    .legacy .content-box.img-box .image-wrapper,
    .pt_brand .content-box.img-box .image-wrapper {
      width: 100%; }
      .legacy .content-box.img-box .image-wrapper img,
      .pt_brand .content-box.img-box .image-wrapper img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover; }

.legacy .brand-nav,
.pt_brand .brand-nav {
  position: relative;
  height: auto;
  z-index: 2; }
  @media only screen and (min-width: 768px) {
    .legacy .brand-nav,
    .pt_brand .brand-nav {
      height: 40px;
      overflow: visible; } }
  .legacy .brand-nav ul,
  .pt_brand .brand-nav ul {
    position: relative;
    float: left;
    width: 100%; }
    .legacy .brand-nav ul li,
    .pt_brand .brand-nav ul li {
      position: relative;
      width: 100%; }
      .legacy .brand-nav ul li a,
      .pt_brand .brand-nav ul li a {
        position: relative;
        float: left;
        border: 1px solid white;
        text-decoration: none;
        display: block;
        float: left;
        width: 100%;
        padding: 8px 0 8px 5px;
        color: white; }
      .legacy .brand-nav ul li .expand,
      .pt_brand .brand-nav ul li .expand {
        position: absolute;
        width: 10px;
        height: 10px;
        right: 15px;
        top: 15px;
        font-size: 25px;
        line-height: .5em; }
  .legacy .brand-nav > ul,
  .pt_brand .brand-nav > ul {
    display: block;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .legacy .brand-nav > ul,
      .pt_brand .brand-nav > ul {
        max-width: 780px;
        margin: 0 auto; } }
    .legacy .brand-nav > ul > li,
    .pt_brand .brand-nav > ul > li {
      display: block;
      float: left; }
      @media only screen and (min-width: 768px) {
        .legacy .brand-nav > ul > li,
        .pt_brand .brand-nav > ul > li {
          text-align: center;
          max-width: 33.3%; } }
      .legacy .brand-nav > ul > li > a > .expand,
      .pt_brand .brand-nav > ul > li > a > .expand {
        display: block; }
        @media only screen and (min-width: 768px) {
          .legacy .brand-nav > ul > li > a > .expand,
          .pt_brand .brand-nav > ul > li > a > .expand {
            display: none; } }
      .legacy .brand-nav > ul > li:nth-child(1),
      .pt_brand .brand-nav > ul > li:nth-child(1) {
        background-color: #68478d; }
        .legacy .brand-nav > ul > li:nth-child(1) a:focus,
        .pt_brand .brand-nav > ul > li:nth-child(1) a:focus {
          outline-color: #68478d; }
      .legacy .brand-nav > ul > li:nth-child(2),
      .pt_brand .brand-nav > ul > li:nth-child(2) {
        background-color: #e62144; }
        .legacy .brand-nav > ul > li:nth-child(2) a:focus,
        .pt_brand .brand-nav > ul > li:nth-child(2) a:focus {
          outline-color: #e62144; }
      .legacy .brand-nav > ul > li:nth-child(3),
      .pt_brand .brand-nav > ul > li:nth-child(3) {
        background-color: #40a949; }
        .legacy .brand-nav > ul > li:nth-child(3) a:focus,
        .pt_brand .brand-nav > ul > li:nth-child(3) a:focus {
          outline-color: #40a949; }
  .legacy .brand-nav > ul > li > ul,
  .pt_brand .brand-nav > ul > li > ul {
    display: none; }
    .legacy .brand-nav > ul > li > ul li,
    .pt_brand .brand-nav > ul > li > ul li {
      text-align: left; }
      .legacy .brand-nav > ul > li > ul li a,
      .pt_brand .brand-nav > ul > li > ul li a {
        padding-left: 15px; }
  .legacy .brand-nav > ul > li > ul > li > ul,
  .pt_brand .brand-nav > ul > li > ul > li > ul {
    display: none;
    background-color: #f28a21; }
    .legacy .brand-nav > ul > li > ul > li > ul li a,
    .pt_brand .brand-nav > ul > li > ul > li > ul li a {
      padding-left: 25px; }
    .legacy .brand-nav > ul > li > ul > li > ul li .expand,
    .pt_brand .brand-nav > ul > li > ul > li > ul li .expand {
      position: absolute;
      width: 10px;
      height: 10px;
      right: 15px;
      top: 15px;
      font-size: 25px;
      line-height: .5em; }
  .legacy .brand-nav > ul > li > ul > li > ul > li > ul,
  .pt_brand .brand-nav > ul > li > ul > li > ul > li > ul {
    display: none;
    background-color: #68478d; }
    .legacy .brand-nav > ul > li > ul > li > ul > li > ul li a,
    .pt_brand .brand-nav > ul > li > ul > li > ul > li > ul li a {
      padding-left: 25px; }

.legacy,
.pt_brand {
  display: block;
  margin: auto; }
  .legacy .center,
  .pt_brand .center {
    text-align: center; }
  .legacy .bold,
  .pt_brand .bold {
    font-weight: bold; }
  .legacy .left,
  .pt_brand .left {
    text-align: left; }
  .legacy .right,
  .pt_brand .right {
    text-align: right; }
  .legacy .image-wrapper,
  .pt_brand .image-wrapper {
    font-size: 0; }
  .legacy .inline-image,
  .pt_brand .inline-image {
    float: left;
    padding: 0 20px 20px 0; }
  .legacy *,
  .pt_brand * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    max-width: initial; }
  .legacy .fullbleed-content,
  .pt_brand .fullbleed-content {
    margin-bottom: 50px; }
    @media only screen and (min-width: 480px) {
      .legacy .fullbleed-content,
      .pt_brand .fullbleed-content {
        margin-bottom: 70px; } }
  .legacy .fullbleed-content::after,
  .pt_brand .fullbleed-content::after {
    content: ""; }
  .legacy .button,
  .pt_brand .button {
    cursor: pointer;
    display: inline-block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: auto;
    padding: 8px;
    gap: 8px;
    font-family: SofiaPro,Arial,sans-serif;
    font-weight: 400;
    font-size: 12px;
    font-size: .75rem;
    letter-spacing: normal;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    min-width: 80px;
    color: #fff;
    background-color: #e62144;
    border: 1px solid #e62144;
    max-width: 286px; }
    .legacy .button.large,
    .pt_brand .button.large {
      font-weight: 700;
      font-size: 16px;
      font-size: 1rem;
      padding: 14px;
      gap: 12px; }

.legacy,
.pt_brand {
  padding-top: 40px; }
  .legacy h1,
  .pt_brand h1 {
    text-align: center;
    margin: 40px auto 20px;
    display: block;
    clear: both;
    padding-top: 20px; }
  .legacy .faq h4,
  .pt_brand .faq h4 {
    cursor: pointer;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 30px 0; }
  .legacy .page-body ul,
  .pt_brand .page-body ul {
    margin: 0 0 20px 20px; }
    .legacy .page-body ul li,
    .pt_brand .page-body ul li {
      list-style: disc; }
  .legacy .page-body ol li,
  .pt_brand .page-body ol li {
    list-style: decimal;
    margin-left: 20px; }
  .legacy .page-body .light,
  .pt_brand .page-body .light {
    color: #01afec; }

@media only screen and (min-width: 480px) {
  .legacy .flex-container,
  .pt_brand .flex-container {
    margin: 1em 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; } }

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .legacy .flex-container,
  .pt_brand .flex-container {
    margin: 0; } }

.legacy .flex-container .flex-item,
.pt_brand .flex-container .flex-item {
  width: 100%;
  margin: 0 5px 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media only screen and (min-width: 480px) {
    .legacy .flex-container .flex-item,
    .pt_brand .flex-container .flex-item {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      float: none !important;
      width: 100%; } }
